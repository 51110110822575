import { Icon, Link } from "@material-ui/core";
import { createTooltip } from "app/main/utils/tableUtils";
import * as fileService from "app/services/fileServices";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import { useDispatch } from "react-redux";
import FilesIconAnalize from "app/main/files/FilesIconAnalize.js";
import { isEnabled } from "app/services/featureStorageService";
import { capitalizaEveryWordFirstLetter } from "helpers/Formatter";
import { processTierRestrictions } from "app/main/utils/rolesUtils";
import { getUserTier } from "app/services/LoginService";

function TripFilesView({ trip, isPublicView }) {
	const dispatch = useDispatch();

	const processRestrictions = processTierRestrictions({ restrictions: ["TIER_PROFESSIONAL"], tier: getUserTier() });
	const isEnabledFilesAnalysis = isPublicView
		? trip.showFilesAnalysis
		: isEnabled("ANALIZE_FILES_UPLOADED") && processRestrictions;

	const onclickFileLink = (e, link) => {
		e.preventDefault();
		dispatch(
			openFormDialog({
				viewId: "FILE_VISUALIZER_VIEW",
				dataIds: {
					urlFilePreview: link,
				},
			})
		);
	};

	const getFiles = (files, type) => {
		const filesType = files?.filter((f) => f.type === type);
		const title = type === "pod" ? "bill_of_lading" : type;

		return (
			<>
				{filesType?.map((f, i) => {
					return (
						<div
							key={i + "_" + type}
							className="border-b-1 border-grey-300 text-12 font-medium break-normal items-center flex flex-row w-full justify-between h-36 pt-2"
						>
							<Link
								key={f.file_id[0]}
								onClick={(e) => onclickFileLink(e, fileService.buildLink(f.file_id[0], false, isPublicView))}
							>
								{type !== "other"
									? capitalizaEveryWordFirstLetter(title) + (filesType.length > 1 ? ` #${i + 1}` : "")
									: f.description}
							</Link>

							{isEnabledFilesAnalysis && (
								<div className="flex flex-row">
									<div className="flex -mb-4">
										<FilesIconAnalize data={f?.analize} style={{ icon: "text-20 mt-0 mr-2" }} />
									</div>
								</div>
							)}
							{!isEnabledFilesAnalysis && (
								<>
									{createTooltip(
										<Icon className="text-20 text-primary-400 font -mb-4">receipt_long</Icon>,
										capitalizaEveryWordFirstLetter(title)
									)}
								</>
							)}
						</div>
					);
				})}
			</>
		);
	};

	return (
		<>
			{trip?.rate_con_file__flag && (
				<div className="border-b-1 border-grey-300 text-12 font-medium break-normal items-center flex flex-row w-full justify-between h-36 pt-2">
					<Link onClick={(e) => onclickFileLink(e, fileService.buildLink(trip?.rate_con_file, false, isPublicView))}>
						<div>Rate Confirmation</div>
					</Link>
					{createTooltip(<Icon className="text-20 text-primary-400 -mb-4">request_quote</Icon>, "Rate Confirmation")}
				</div>
			)}
			{getFiles(trip?.pod_files, "pod")}
			{getFiles(trip?.pod_files, "rate_confirmation")}
			{getFiles(trip?.pod_files, "proof_of_delivery")}
			{getFiles(trip?.pod_files, "packing_list")}
			{getFiles(trip?.pod_files, "lumper_receipt")}
			{getFiles(trip?.pod_files, "dock/warehouse_receipt")}
			{getFiles(trip?.pod_files, "scale_or_weight_receipt")}
			{getFiles(trip?.pod_files, "cargo_picture")}
			{getFiles(trip?.pod_files, "display_screen")}
			{getFiles(trip?.pod_files, "other")}
		</>
	);
}

export default TripFilesView;
