import {
	truckPlanFieldsToFilters,
	DEFAULT_HARD_FILTERS_VALUES,
	DEFAULT_SOFT_FILTERS_VALUES,
} from "../utils/filtersUtils";

export const convertPlanAndTruckToSearch = (carrier, truck, plan) => {
	const trailer = truck.truck_trailer__view.metadata;
	const filters = plan
		? truckPlanFieldsToFilters(plan)
		: { hardFilters: DEFAULT_HARD_FILTERS_VALUES, softFilters: DEFAULT_SOFT_FILTERS_VALUES };

	return {
		EMPTY: {
			filters: {
				...filters.hardFilters,
				...{
					carrier: carrier?._id,
					carrier__view: carrier?.carrier__view
						? carrier.carrier__view
						: {
								value: carrier?._id,
								label: carrier?.carrier_company_name,
								description: "MC " + (carrier?.carrier_mc_number ?? " Empty"),
						  },
					truck: truck?._id,
					truck__view: truck?.truck__view
						? truck.truck__view
						: {
								value: truck?._id,
								label: truck?._id,
								description: truck ? `${truck?.trailer}' | ${truck?.max_weight} lb` : "",
						  },
					location_origin: plan?.current_location,
					location_origin__view: plan?.current_location__view
						? plan?.current_location__view
						: { label: plan?.current_location, value: plan?.current_location },
					pickup_ds__after: plan?.current_available_date,
					equipment: truck?.subtype ? "SPECIAL_" + truck.subtype : truck?.equipment,
					max_weight: filters?.hardFilters?.max_weight ?? trailer?.max_weight,
					load_type: trailer?.load_type ?? filters?.hardFilters?.load_type,
					trailer: filters?.hardFilters?.trailer ?? trailer?.trailer,
				},
			},
			softFilters: filters?.softFilters,
		},
	};
};

export const saveSearchesLocalStorage = async (searches) => {
	let searchesLocalStorage = {};
	for (const key in searches) {
		searchesLocalStorage[key] = {
			filters: searches[key].filters,
			softFilters: searches[key].softFilters,
			order: searches[key].order,
		};
	}
	if (searchesLocalStorage) localStorage.setItem("SEARCH_LIST", JSON.stringify(searchesLocalStorage));
};
