import clsx from "clsx";
import moment from "moment";

import { useSelector, useDispatch } from "react-redux";
// import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { CircularProgress, Typography, Button, Icon } from "@material-ui/core";
import { SmarthopPickerField, SmarthopAutocompleteField } from "@smarthop/form";

import { makeStyles } from "@material-ui/core/styles";
import { getDriverId, getCarrierId } from "app/services/LoginService";
import { NARROW_FIELDS } from "@fuse/default-settings";
import authRoles from "../../consts/authRoles";

import { createSearch, setSearchParams, fetchSearchResults } from "app/store/search/searchV3Slice";
import { fetchCredentialsStatus, clearCredentials } from "app/store/search/credentialsSlice";
import { isEnabled } from "app/services/featureStorageService";

import SearchFilters from "./SearchFilters";
import {
	DEFAULT_HARD_FILTERS_VALUES,
	DEFAULT_SOFT_FILTERS_VALUES,
	filtersChanged,
	truckPlanFieldsToFilters,
	driverPreferencesToFilters,
	truckToFilters,
} from "../utils/filtersUtils";

const useStyles = makeStyles((theme) => {
	return {
		field: {
			background: "white",
			borderRadius: "4px",
		},
		buttonNormal: {
			borderRadius: "4px",
			height: NARROW_FIELDS ? "4.6rem" : "5.2rem",
		},
		buttonDisabled: {
			background: theme.palette.grey["400"] + " !important",
		},
		leftPanelHeight: {
			height: "calc(100vh - 220px) !important",
		},
		"@keyframes flicker": {
			from: {
				opacity: 1,
			},
			to: {
				opacity: 0.2,
			},
		},
		flicker: {
			animationName: "$flicker",
			animationDuration: "1000ms",
			animationIterationCount: "infinite",
			animationDirection: "alternate",
			animationTimingFunction: "ease-in-out",
		},
		withAnimation: ({ disabled }) => ({
			animationPlayState: disabled ? "paused" : "running",
		}),
	};
});

const GENERIC_TRUCKS_SPECIAL = [
	{
		value: "ANY_SPECIAL_Tanker",
		label: "Tanker",
		description: "All Tanker Loads",
		metadata: {
			truck: {
				equipment: "SPECIAL",
				max_weight: 70000,
				load_type: "FULL",
				trailer: 53,
				subtype: "Tanker",
			},
		},
		icon: "local_shipping",
		selectable: true,
	},
	{
		value: "ANY_SPECIAL_Deck",
		label: "Deck",
		description: "All Deck Loads",
		metadata: {
			truck: {
				equipment: "SPECIAL",
				max_weight: 55000,
				load_type: "FULL",
				trailer: 53,
				subtype: "Deck",
			},
		},
		icon: "local_shipping",
		selectable: true,
	},
	{
		value: "ANY_SPECIAL_HotShot",
		label: "HotShot",
		description: "All HotShot Loads",
		metadata: {
			truck: {
				equipment: "SPECIAL",
				max_weight: 30000,
				load_type: "FULL",
				trailer: 53,
				subtype: "HotShot",
			},
		},
		icon: "local_shipping",
		selectable: true,
	},
	{
		value: "ANY_SPECIAL_Conestoga",
		label: "Conestoga",
		description: "All Conestoga Loads",
		metadata: {
			truck: {
				equipment: "SPECIAL",
				max_weight: 48000,
				load_type: "FULL",
				trailer: 53,
				subtype: "Conestoga",
			},
		},
		icon: "local_shipping",
		selectable: true,
	},
	{
		value: "ANY_SPECIAL_Cargo Van",
		label: "Cargo Van",
		description: "All Cargo Van Loads",
		metadata: {
			truck: {
				equipment: "SPECIAL",
				max_weight: 7500,
				load_type: "BOTH",
				trailer: 15,
				subtype: "Cargo Van",
			},
		},
		icon: "local_shipping",
		selectable: true,
	},
	{
		value: "ANY_SPECIAL_Box Truck",
		label: "Box Truck",
		description: "All Box Truck Loads",
		metadata: {
			truck: {
				equipment: "SPECIAL",
				max_weight: 26000,
				load_type: "BOTH",
				trailer: 26,
				subtype: "Box Truck",
			},
		},
		icon: "local_shipping",
		selectable: true,
	},
];

function RequestPanel(props) {
	// Native fields
	// TODO: isNativeEnv works when props.nativeMobile is not being passed
	// TODO: Could isNativeEnv replace props.nativeMobile?
	const { flicker, withAnimation } = useStyles({ disabled: false });
	// const isNativeEnv = window?.location?.href?.includes("/native/");
	// const location = useLocation();
	const nativeMobile = props.nativeMobile;
	const searches = useSelector(({ search }) => search.searchV3Slice.searches);
	const currentSearchId = useSelector(({ search }) => search.searchV3Slice.currentSearch);
	const currentSearch = searches[currentSearchId];
	const [hardFilters, setHardFilters] = useState(currentSearch?.filters);
	const [softFilters, setSoftFilters] = useState(
		Object.assign({}, DEFAULT_SOFT_FILTERS_VALUES, currentSearch?.softFilters)
	);

	const user = useSelector(({ auth }) => auth.user);
	const roles = user.rolesLabels;
	const subaccount = useSelector(({ auth }) => auth.account.subAccount);
	const hasSubaccounts = subaccount?.hasSubaccounts;

	// getting role with most of the permissions
	let role = roles.includes("administrator")
		? "administrator"
		: roles.includes("dispatcher")
		? "dispatcher"
		: roles.includes("ops support")
		? "ops support"
		: roles.includes("carrier")
		? "carrier"
		: roles.includes("driver")
		? "driver"
		: roles.includes("carrier_manager")
		? "carrier_manager"
		: roles.includes("carrier_dispatcher")
		? "carrier_dispatcher"
		: undefined;

	const carrierId = ["carrier", "carrier_manager", "carrier_dispatcher", "driver", "carrier_generic"].includes(role)
		? getCarrierId()
		: null;

	const isAdmin = authRoles.internalPowerUsers.includes(role);
	const isDispatch = authRoles.dispatcher.includes(role);
	const isCarrier = authRoles.carrier.includes(role);
	const isDriver = authRoles.driver.includes(role);
	const isManager = authRoles.carrierManager.includes(role);
	const isInvestor = authRoles.carrierGeneric.includes(role);
	const isExtDispatcher = authRoles.carrierDispatcher.includes(role);
	// const isInternal = authRoles.internalUsers.includes(role);
	const isMainHandler = hasSubaccounts && (isCarrier || isManager || isExtDispatcher);
	const driverId = role === "driver" ? getDriverId() : null;

	const dispatch = useDispatch();
	const [mobile, setMobile] = useState(window.innerWidth < 960);
	const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1800);

	//Update local filters if redux filters changed
	useEffect(() => {
		setHardFilters(Object.assign({}, DEFAULT_HARD_FILTERS_VALUES, currentSearch?.filters));
	}, [currentSearch?.filters]);

	useEffect(() => {
		setSoftFilters(Object.assign({}, currentSearch?.softFilters));
	}, [currentSearch?.softFilters]);

	//Update local hardFilters if the tab changed
	useEffect(() => {
		if (currentSearch?.filters && Object.keys(currentSearch?.filters).length > 0) {
			setHardFilters(currentSearch.filters);
		}
	}, [currentSearch?.filters, currentSearchId]);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 960 && !mobile) {
				setMobile(true);
			} else if (window.innerWidth > 960 && mobile) {
				setMobile(false);
			}
			if (window.innerWidth < 1600 && window.innerWidth > 960 && !smallScreen) {
				setSmallScreen(true);
			} else if ((window.innerWidth > 1600 || window.innerWidth < 960) && smallScreen) {
				setSmallScreen(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	useEffect(() => {
		if (!hardFilters?.pickup_ds__after || moment(hardFilters?.pickup_ds__after).isBefore(moment().startOf("day")))
			return setHardFilters(Object.assign({}, hardFilters, { pickup_ds__after: moment().toISOString() }));
		// eslint-disable-next-line
	}, [hardFilters?.pickup_ds__after]);

	const runSearch = () => {
		const softFiltersChanged = filtersChanged(softFilters, currentSearch?.softFilters);
		const hardFiltersChanged = filtersChanged(hardFilters, currentSearch?.filters);

		if (softFiltersChanged && !hardFiltersChanged) {
			dispatch(
				setSearchParams({
					searchId: currentSearchId,
					softFilters,
					override: { hardFilters: false, softFilters: true },
				})
			);
		} else {
			const preloading = currentSearch?.state === "QUEUEING";
			const preloadingFinished = currentSearch?.state === "FINISHED_PRELOADED";
			if (!hardFiltersChanged && (preloading || preloadingFinished)) {
				//If the search still preloading and hard filters didn't changed, refresh only
				dispatch(fetchSearchResults({ searchId: currentSearchId, userId: user._id }));
			} else {
				let hardFiltersToSend = {
					...hardFilters,
					pickup_ds__after: moment(hardFilters?.pickup_ds__after).format() ?? "",
					pickup_ds__before: hardFilters?.pickup_ds__before
						? moment(hardFilters?.pickup_ds__before).format()
						: undefined,
					webMobile: mobile,
					webNative: nativeMobile,
				};

				// when driver and carrier searches for a load UI does not provide carrier selection,
				// so we need to use local carrier id and add it to request body
				if (!hardFiltersToSend.carrier) {
					hardFiltersToSend.carrier = carrierId;
					hardFiltersToSend.carrier__view = carrierId ? { value: carrierId, label: "ID " + carrierId } : null;
				}

				dispatch(createSearch({ userId: user._id, filters: hardFiltersToSend, softFilters }));
			}
		}
	};

	//Update filters on local state
	const updateFilter = (filters, isHardFilter) => {
		if (isHardFilter) {
			setHardFilters(Object.assign({}, hardFilters, filters));
		} else {
			setSoftFilters(Object.assign({}, softFilters, filters));
		}
	};

	const carrierField = () => {
		if (!(isAdmin || isDispatch || isMainHandler)) return null;
		return (
			<SmarthopAutocompleteField
				name="carrier"
				label="Carrier"
				inputValue={hardFilters?.carrier__view?.label ?? ""}
				optionsValue={hardFilters?.carrier__view?.value ? [hardFilters?.carrier__view] : []}
				required={true}
				onSelected={(optionsValues) => {
					updateFilter(
						{
							carrier__view: optionsValues?.[0],
							carrier: optionsValues?.[0]?.value,
							truck__view: undefined,
							truck: undefined,
						},
						true
					);
					if (optionsValues?.[0]?.value) {
						dispatch(fetchCredentialsStatus({ carrierId: optionsValues?.[0]?.value }));
					} else {
						dispatch(clearCredentials());
					}
				}}
				field={{ noErrorMessage: true }}
				autocomplete={{
					provider: "smarthop",
					url: "api/profile/carriers",
					preloadDefault: true,
					preloadImmedately: true,
					listenEvent: "profileRevision",
					showDescription: true,
					params: {
						options_active_only: true,
						options_onboarding_completed: true,
						options_my_assigned: isDispatch,
						options_disabled_search_trial_expired: isDispatch,
					},
				}}
			/>
		);
	};

	const truckField = () => {
		if (
			!(isAdmin || isDispatch || isMainHandler || isCarrier || isManager || isInvestor || isExtDispatcher || isDriver)
		)
			return null;

		const carrierSelected = hardFilters?.carrier ? hardFilters?.carrier : getCarrierId();

		let url = isDriver
			? `api/profile/carriers/${carrierSelected}/drivers/${driverId}/trucks`
			: isDispatch || isMainHandler || isAdmin
			? "api/profile/trucks"
			: isCarrier || isManager || isInvestor || isExtDispatcher
			? `api/profile/carriers/${carrierSelected}/trucks`
			: undefined;
		return (
			<SmarthopAutocompleteField
				name="truck"
				label="Truck"
				inputValue={hardFilters?.truck__view?.label ?? ""}
				optionsValue={hardFilters?.truck__view?.value ? [hardFilters?.truck__view] : []}
				referenceParam={
					isAdmin || isDispatch || isMainHandler
						? {
								valueRef: "carrier",
								paramKey: "options_carrier_id_search",
						  }
						: {}
				}
				referenceValue={!!carrierSelected ? carrierSelected : null}
				required={true}
				orignialValue={null}
				onSelected={(optionsValues) => {
					if (optionsValues?.[0]) {
						const truckPlanFilters =
							!optionsValues?.[0]?.value?.includes("ANY_") && optionsValues?.[0]?.metadata?.activePlan
								? truckPlanFieldsToFilters(optionsValues?.[0]?.metadata)
								: undefined;
						const truckFilters = truckToFilters(optionsValues?.[0]?.metadata);
						const driverFilters = driverPreferencesToFilters(optionsValues?.[0]?.metadata?.driver__view?.metadata);
						updateFilter(
							{
								carrier: carrierSelected,
								truck__view: optionsValues?.[0],
								truck: optionsValues?.[0]?.value,
								...(truckPlanFilters?.hardFilters ? truckPlanFilters.hardFilters : {}),
								...(truckFilters ? truckFilters.hardFilters : {}),
								...(driverFilters ? driverFilters : {}),
							},
							true
						);
						updateFilter(
							{ ...(truckPlanFilters?.softFilters ? truckPlanFilters.softFilters : {}), ...truckFilters?.softFilters },
							false
						);
					} else {
						//Cleanup filters related to truck
						dispatch(
							setSearchParams({
								searchId: currentSearchId,
								softFilters: DEFAULT_SOFT_FILTERS_VALUES,
								hardFilters: {
									...DEFAULT_HARD_FILTERS_VALUES,
									carrier: hardFilters.carrier,
									carrier__view: hardFilters.carrier__view,
									location_origin: hardFilters.location_origin,
									location_origin__view: hardFilters.location_origin__view,
								},
								override: { hardFilters: true, softFilters: true },
							})
						);
					}
				}}
				field={{ noErrorMessage: true }}
				autocomplete={{
					provider: "smarthop",
					url: url,
					preloadDefault: !!carrierSelected,
					preloadImmedately: !!carrierSelected,
					listenEvent: "profileRevision",
					showDescription: true,
					params: {
						options_metadata_truck: true,
						options_metadata_driver: true,
						options_active_only: true,
						label_format_show_driver: true,
						options_search_truck_plan: true,
						options_format_show_capacity: true,
						options_my_assigned: !isDispatch ? false : true,
						options_carrier_id_search: carrierSelected,
					},
					actionOptions: [
						...(isMainHandler || isCarrier || isManager || isInvestor || isExtDispatcher || isAdmin
							? [
									{
										value: "ANY_REEFER",
										label: "Reefer",
										description: "All Reefer Loads",
										metadata: {
											truck: {
												equipment: "REEFER",
												max_weight: 44000,
												load_type: "FULL",
												trailer: 53,
											},
										},
										icon: "local_shipping",
										selectable: true,
									},
									{
										value: "ANY_VAN",
										label: "Van",
										description: "All Van Loads",
										metadata: {
											truck: {
												equipment: "VAN",
												max_weight: 45000,
												load_type: "FULL",
												trailer: 53,
											},
										},
										icon: "local_shipping",
										selectable: true,
									},
									{
										value: "ANY_FLATBED",
										label: "Flatbed",
										description: "All Flatbed Loads",
										metadata: {
											truck: {
												equipment: "FLATBED",
												max_weight: 48000,
												load_type: "FULL",
												trailer: 53,
											},
										},
										icon: "local_shipping",
										selectable: true,
									},
									{
										value: "ANY_PO",
										label: "Power Only",
										description: "All Power Only Loads",
										metadata: {
											truck: {
												equipment: "PO",
											},
										},
										icon: "local_shipping",
										selectable: true,
									},
							  ]
							: []),
						...(isEnabled("ENABLE_SPECIAL_EQUIPMENTS") ? GENERIC_TRUCKS_SPECIAL : []),
						...(isCarrier || isManager || isInvestor || isExtDispatcher
							? [
									{
										value: "ADD_NEW_TRUCK",
										label: "Add New Truck",
										icon: "add",
										disabled: isExtDispatcher || isInvestor ? true : false,
										dialog: { viewId: "TRUCK_VIEW", dataIds: { mode: "CREATE" }, mode: "CREATE" },
									},
							  ]
							: []),
					],
				}}
			/>
		);
	};

	const locationField = (
		<SmarthopAutocompleteField
			name="location_origin"
			label="Origin"
			inputValue={hardFilters?.location_origin__view?.label ?? ""}
			optionsValue={hardFilters?.location_origin__view?.value ? [hardFilters?.location_origin__view] : []}
			required={true}
			onSelected={(optionsValues) => {
				updateFilter({ location_origin__view: optionsValues?.[0], location_origin: optionsValues?.[0]?.value }, true);
			}}
			field={{ noErrorMessage: true }}
			autocomplete={{
				provider: "trimblemaps",
				formatST: true,
				params: {
					componentRestrictions: { country: "us", includeOnly: "city" },
					types: ["(cities)"],
				},
				multiple: false,
				anyValue: false,
				icon: "location",
			}}
		/>
	);

	const availableField = (
		<SmarthopPickerField
			key="search_pickup_ds__after"
			type="date"
			name="pickup_ds__after"
			label="Available"
			required={true}
			disabled={false}
			value={moment(hardFilters?.pickup_ds__after).utc()}
			onChange={(value) => {
				updateFilter({ pickup_ds__after: value }, true);
			}}
			field={{ noErrorMessage: true }}
			picker={{
				disablePast: true,
				notClearable: true,
				type: "date",
				classes: {},
			}}
		/>
	);

	const filtersButton = (
		<SearchFilters
			key={"search_filters_" + currentSearchId}
			flicker={flicker}
			withAnimation={withAnimation}
			hardFilters={hardFilters}
			softFilters={softFilters}
			onFilterChanged={updateFilter}
			onSearch={runSearch}
			carrierField={carrierField()}
			truckField={truckField()}
			isMobile={mobile}
		/>
	);

	const searchButton = () => {
		let creatingSearch = currentSearch?.processing === true;
		let disabled =
			!hardFilters?.carrier ||
			!hardFilters?.truck__view ||
			!hardFilters?.location_origin__view ||
			!hardFilters?.pickup_ds__after ||
			creatingSearch;
		const hardFiltersChanged = filtersChanged(hardFilters, currentSearch?.filters);
		const softFiltersChanged = filtersChanged(softFilters, currentSearch?.softFilters);
		const searchExpired = currentSearch?.state === "EXPIRED" || currentSearch?.refresh?.state === "EXPIRED";

		const design = {
			// TODO move action const into action or component object
			color: "secondary",
			variant: "contained",
			className: ` ${
				(hardFiltersChanged || softFiltersChanged || searchExpired) && !disabled ? `${flicker} ${withAnimation}` : ""
			} ${mobile ? "mb-4 mt-4 pt-4 pb-4 flex relative justify-end" : "my-6 ml:my-0"}`,
		};

		const text = "Search";
		const label = !mobile ? (
			<Typography className={"font-semibold " + (window.ismobile() ? " text-white " : "")}>{text}</Typography>
		) : (
			<Icon>search</Icon>
		);

		let buttonContent = label;
		const defaultLoadingProps = { color: "inherit", size: "1.5em" };
		buttonContent = creatingSearch ? (
			<div className="flex items-center justify-center px-2">
				<CircularProgress
					{...{
						...defaultLoadingProps,
					}}
				/>
			</div>
		) : (
			buttonContent
		);

		let button = (
			<Button
				{...design}
				onClick={runSearch}
				disabled={disabled}
				value="legacy"
				style={{ borderRadius: "4px", height: NARROW_FIELDS ? "4.6rem" : "5.2rem" }}
			>
				{buttonContent}
			</Button>
		);

		return (
			<div className={`${!mobile ? "w-full px-4  mb-4 mt-4  pt-4 pb-4" : "px-4 mb-4 mt-4  pt-2 pb-2"}`}>
				<div key={"SEARCH_SUBMIT"} className={clsx(`${!mobile ? "flex flex-col mx-4 w-full" : ""}`)}>
					{button}
				</div>
			</div>
		);
	};
	return !mobile ? (
		<div className={clsx("flex flex-row w-full px-14 py-6 items-center")}>
			{carrierField()}
			{truckField()}
			{locationField}
			{availableField}
			{filtersButton}
			{searchButton()}
		</div>
	) : (
		<div className={clsx("flex flex-col w-full px-14 py-6 items-center")}>
			<div className="flex flex-row flex-1 w-full items-center">
				{locationField}
				{filtersButton}
			</div>
			<div className="flex flex-row flex-1 w-full items-center">
				{availableField}
				{searchButton()}
			</div>
		</div>
	);
}

export default RequestPanel;
