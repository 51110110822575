import { useParams } from "react-router-dom";
import { getUserId, isRoleExternal } from "app/services/LoginService";
import FormSection from "app/main/tools/FormSection";
import PageWrapper from "../../common/PageWrapper";
import PageSection from "../../common/PageSection";
import { useSelector } from "react-redux";
import { usersLinkedAccountsList, usersEmailAliasList, SmarthopList } from "@smarthop/list";
import userConfigForm from "@smarthop/form/configs/userConfigForm";
import usePayrollHelper from "@smarthop/hooks/usePayrollHelper";

function UserDetailsPage(props) {
	const params = useParams();

	const viewerUserId = getUserId();
	const viewerExternal = isRoleExternal();

	const user = useSelector(({ auth }) => auth.user);
	const roles = props?.isView && props?.params?.role ? [props?.params?.role?.label] : user?.rolesLabels;
	const nativeMobile = useSelector(({ auth }) => auth.user.nativeMobile);

	const {
		auth: { showPayrollFieldsToSessionUser },
	} = usePayrollHelper();

	const enabledEmailAliasConfig =
		roles.includes("carrier") || roles.includes("carrier_manager") || roles.includes("carrier_dispatcher");
	const userId = props.isView && props.params ? props.params.userId : params.userId ?? viewerUserId;
	const changePassword = {
		action: "edit_pwd",
		label: "Change Password",
		auth: [
			"administrator",
			"dispatcher",
			"ops support",
			"carrier",
			"driver",
			"carrier_manager",
			"carrier_dispatcher",
			"carrier_generic",
		],
		dialog: { formId: "USER_PASSWORD_CHANGE_FORM", mode: "CREATE", dataIds: { userId } },
	};

	const emailAliasAction = {
		action: "create",
		label: "Add Email",
		auth: ["carrier", "carrier_manager", "carrier_dispatcher"],
		dialog: { formId: "USER_EMAIL_ALIAS_FORM", mode: "CREATE", dataIds: { userId } },
	};

	const isMyProfile = userId === viewerUserId;

	const createUserDetailsView = () => {
		return (
			<div className="flex flex-col w-full pb-40">
				<PageSection variant="narrow" title="Details" titleActions={isMyProfile ? [changePassword] : []}>
					<div className="flex flex-col w-full">
						<div className="flex flex-col md:flex-row w-full space-x-0 md:space-x-10">
							<FormSection
								title="Personal Information"
								formId="USER_DETAILS_FORM"
								dataIds={{ userId }}
								actions={isMyProfile ? [{ action: "edit", mode: "EDIT", label: "Edit" }] : []}
							/>
							{!viewerExternal && <FormSection title="Last Login" formId="USER_LAST_LOGIN_FORM" dataIds={{ userId }} />}
							{viewerExternal && <div className="flex flex-row w-full p-10"></div>}
						</div>
						<div className="flex flex-col md:flex-row w-full space-x-0 md:space-x-10">
							<FormSection
								title="Phone Information"
								formId="USER_DETAILS_PHONE_FORM"
								dataIds={{ userId }}
								actions={isMyProfile ? [{ action: "edit", mode: "EDIT", label: "Edit" }] : []}
							/>
							<FormSection
								title="Email Information"
								formId="USER_DETAILS_EMAIL_FORM"
								dataIds={{ userId }}
								actions={isMyProfile ? [{ action: "edit", mode: "EDIT", label: "Edit" }] : []}
							/>
						</div>
						{!viewerExternal && props?.params?.driverId && (
							<div className="flex flex-col md:flex-row w-1/2 space-x-0 md:space-x-10">
								<FormSection
									title="Axle Driver"
									formId="USER_AXLE_FORM"
									dataIds={{ driverId: props.params.driverId, carrierId: props.params.carrierId }}
								/>
							</div>
						)}
					</div>
				</PageSection>
				{enabledEmailAliasConfig && !nativeMobile && (
					<PageSection
						title="Eligible to email RateCons"
						resource={{ icon: "EMAIL_ALIAS_INFO" }}
						variant="narrow"
						titleActions={[emailAliasAction]}
					>
						<SmarthopList
							key="eligible"
							urlKey="eligible"
							mode="TABLE"
							isSection={true}
							config={usersEmailAliasList}
							dataIds={{ userId }}
						/>
					</PageSection>
				)}
				{(!viewerExternal || isMyProfile) && !nativeMobile && (
					<PageSection title="Accounts" variant="narrow">
						<SmarthopList
							key="accounts"
							urlKey="accounts"
							mode="TABLE"
							isSection={true}
							config={usersLinkedAccountsList({
								enableSwitch: isMyProfile,
								viewerUserId: viewerUserId,
								isAdmin: !viewerExternal,
							})}
							dataIds={{ userId }}
						/>
					</PageSection>
				)}
				<PageSection title="Internal" variant="narrow" auth={["administrator", "dispatcher", "ops support"]}>
					<FormSection
						title="Configuration"
						form={userConfigForm({ showPayrollFields: showPayrollFieldsToSessionUser })}
						dataIds={{ id: userId }}
						actions={[{ action: "edit", mode: "EDIT", label: "Edit", auth: ["administrator", "ops support"] }]}
						auth={["administrator", "dispatcher", "ops support"]}
					/>
				</PageSection>
			</div>
		);
	};

	return (
		<PageWrapper title={props?.isView ? null : "Profile"} isView={props.isView} variant="narrow">
			{createUserDetailsView()}
		</PageWrapper>
	);
}

export default UserDetailsPage;
