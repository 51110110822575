import { useMemo, useState, useEffect, useRef } from "react";
import { Typography, Icon, Tooltip, Chip, Button } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { DisplaySection } from "app/main/utils/uiUtils";
import { signInWithToken } from "app/services/LoginService";
import { setUserData } from "app/store/auth/userSlice";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import SmarthopDialog from "@smarthop/form/SmarthopDialog";
import PayrollTripsAffectedWarningConfirmDialog from "app/main/profile/users/PayrollTripsAffectedWarningConfirmDialog.js";
import PaywallDialog from "app/main/billing/paywall/PaywallDialog.js";
import HelpRequestDialog from "app/main/support/HelpRequestDialog.js";

import { createUserEditFrom } from "./configs/userEditConfig";
import {
	getDefaultPermissions,
	getAllowedRoles,
	getUserRoles,
	resetPermissions,
	resetOriginalProfiles,
	preprocessFormData,
	postprocessFormData,
	ROLE_OWNER_KEY,
	ROLE_DRIVER_KEY,
	ROLE_INVESTOR_KEY,
	ROLE_DISPATCHER_KEY,
} from "./configs/userRolesUtils";
import { hasRequiredGateKeepers, roleRestrictions } from "app/main/utils/rolesUtils";

import {
	getUserDetails,
	getUserDriverDetails,
	getUsersValidation,
	createCarriertUser,
	updateCarriertUser,
	deactivateCarriertUser,
	getCarrierMainAccountDetails,
} from "app/services/usersServices";
import { getCarrierId, getAccountId, getUserTier } from "app/services/LoginService";
import {
	getCarrierPayrollSettings,
	getCarrierFuelSettings,
	getCarrierInvoiceSettings,
} from "app/services/carrierServices";

function CarrierUsersEditView(props) {
	const snackbar = useSnackbar();
	const dispatch = useDispatch();

	const user = useSelector(({ auth }) => auth.user);
	const subaccount = useSelector(({ auth }) => auth.account.subAccount);

	const native = props.nativeMobile ?? window?.ReactNativeWebView ?? false;

	const cache = props.cache;
	const onDone = props.onDone;
	const dataIds = props?.dataIds;
	const carrierId = props.carrierId ?? dataIds?.carrierId ?? getCarrierId();
	const accountId = getAccountId();
	const userTier = getUserTier();

	const [userId, setUserId] = useState(props.userId ?? dataIds?.userId);
	const driverId = props.driverId ?? dataIds?.driverId;
	const origin = dataIds?.origin;
	const mode = !!userId || !!driverId ? props?.mode ?? dataIds?.mode ?? "EDIT" : "CREATE";

	const [loading, setLoading] = useState(true);
	const [processing, setProcessing] = useState(false);
	const [counter, setCounter] = useState(null);
	const [loadingError, setLoadingError] = useState(null);
	const [processingError, setProcessingError] = useState(null);
	const [formData, setFormData] = useState(null);
	const [formSettings, setFormSettings] = useState(null);
	const [initUserData, setInitUserData] = useState(native ? null : props.cache?.userData);
	const [viewerLikeSubAccount, setViewerLikeSubAccount] = useState(null);
	const [payrollSettings, setPayrollSettings] = useState(
		cache?.payrollSettings && !native
			? { defaults: cache?.payrollSettings.payee_defaults, enabled: cache?.payrollSettings.payroll_module_enabled }
			: null
	);
	const [fuelSettings, setFuelSettings] = useState(
		cache?.fuelSettings && !native ? { enabled: cache?.fuelSettings.onboardingFuelModule === "ENABLED" } : null
	);
	const [invoiceSettings, setInvoiceSettings] = useState(
		cache?.invoiceSettings ? { enabled: cache?.invoiceSettings.enabled } : null
	);
	const [payrollTripsAffected, setPayrollTripsAffected] = useState();
	const [isPayrollTripsAffectedWarningDialogOpen, setIsPayrollTripsAffectedWarningDialogOpen] = useState(false);
	const [paywallDialog, setPaywallDialog] = useState({});
	const [userWarningDialog, setUserWarningDialog] = useState({});
	const [helpRequestDialog, setHelpRequestDialog] = useState(false);
	const ignorePayrollTripsAffectedWarning = useRef(false);

	const invoiceEnabled = invoiceSettings?.enabled;
	const payrollEnabled = dataIds?.historyData?.payroll?.settings ? true : payrollSettings?.enabled;
	const fuelEnabled = fuelSettings?.enabled;
	const billingEnabled = user.roleType === "INTERNAL" || user.role === "CARRIER_OWNER";
	const viewerCanEditAndAccessPayroll =
		user.roleType === "INTERNAL" ||
		(user.roleType === "EXTERNAL" && ["CARRIER_OWNER", "CARRIER_MANAGER", "CARRIER_DISPATCHER"].includes(user.role));
	const viewerCanEditRole =
		user.roleType === "INTERNAL" ||
		(user.roleType === "EXTERNAL" && ["CARRIER_OWNER", "CARRIER_MANAGER"].includes(user.role));
	const hasUsersAccessPermission = roleRestrictions.permission_users_access.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_users_access: true })
		: true;

	useEffect(() => {
		props?.setTitle?.(
			mode === "ACTIVATE"
				? "Activate User"
				: mode === "DELETE"
				? "Deactivate User"
				: mode === "EDIT"
				? "Edit User"
				: "Create User"
		);
		props?.setSize?.(mode === "DELETE" ? "max-w-xl" : "max-w-xl");
		// eslint-disable-next-line
	}, [mode]);

	useEffect(() => {
		if (!carrierId || !user) return;

		(async () => {
			try {
				if (user.roleType === "INTERNAL") {
					const result = await getCarrierMainAccountDetails(carrierId);
					setViewerLikeSubAccount(!result?.isMainAccount);
				} else {
					setViewerLikeSubAccount(
						!user.mainAccount || !!subaccount?.list?.find((item) => item._id + "" === carrierId + "")
					);
				}
			} catch (err) {
				setLoadingError(err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...");
			}
		})();

		// eslint-disable-next-line
	}, [carrierId, user, subaccount?.list]);

	useEffect(() => {
		if (payrollSettings && fuelSettings && invoiceSettings && initUserData) {
			// We don't need to load data if cache has been provided
			return;
		}
		if (props?.dataIds?.historyData) {
			const dataHistory = preprocessFormData(props?.dataIds?.historyData);
			updateFormSettings(dataHistory);
			setLoading(false);
			return;
		}

		(async () => {
			try {
				// Loading data if some has not been passed outside
				const [initUserData, payrollSettings, fuelSettings, invoiceSettings] = await Promise.all([
					userId
						? await getUserDetails(userId, carrierId, { version: 2 })
						: driverId
						? await getUserDriverDetails(driverId, { version: 2 })
						: null,
					// TODO: aggregate carrier settings into a single endpoint
					getCarrierPayrollSettings(carrierId),
					getCarrierFuelSettings(carrierId),
					getCarrierInvoiceSettings(carrierId),
				]);

				// Saving initial user data
				if (!userId && initUserData) {
					setUserId(initUserData.user._id);
				}
				setInitUserData(initUserData ?? {});
				// Saving payroll module settings
				setPayrollSettings({
					defaults: payrollSettings.payee_defaults,
					enabled: payrollSettings.payroll_module_enabled,
				});
				// Saving fuel module settings
				setFuelSettings({ enabled: fuelSettings.onboardingFuelModule === "ENABLED" });
				// Saving invoicing module settings
				setInvoiceSettings({ enabled: invoiceSettings.enabled });
			} catch (err) {
				setLoadingError(err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...");
			}
		})();

		// eslint-disable-next-line
	}, [carrierId]);

	useEffect(() => {
		if (!payrollSettings || !fuelSettings || !invoiceSettings || !initUserData) {
			// Settings not yet loaded, need to wait...
			return;
		}
		if (formData) {
			// Already initialized
			setLoading(false);
			return;
		}

		if (mode === "CREATE") {
			if (origin === "DRIVER") {
				updateFormSettings(
					{ roles: { role_driver: true }, deactivated: false },
					{ roleChanged: true, initialization: true }
				);
			} else {
				updateFormSettings({});
			}
		} else {
			const formData = preprocessFormData(initUserData, { payrollEnabled, invoiceEnabled, billingEnabled });
			updateFormSettings(formData);
		}

		setLoading(false);
		// eslint-disable-next-line
	}, [payrollSettings, fuelSettings, invoiceSettings, initUserData]);

	const updateFormSettings = (data, { roleChanged, driverTypeChanged, initialization } = {}) => {
		const roles = getUserRoles(data.roles);

		// When role is changes we need to reset permissions for that user
		const { values, config, enabled } = getDefaultPermissions(roles[0], {
			payrollEnabled,
			invoiceEnabled,
			billingEnabled,
		});
		const allowedRoles = getAllowedRoles(roles[0]);
		if (roleChanged && formSettings?.role !== roles[0]) {
			data = { ...resetPermissions(data), permissions: values };
		}

		// Carrier owner can not be deactivated
		const canBeDectivated = roles[0] && !data.roles[ROLE_OWNER_KEY];
		const canBeEditEmail = !data.accessAt;

		if (roleChanged) {
			["role_driver", "role_dispatcher", "role_investor"].forEach((key) => {
				if (!formData?.roles?.[key] && !!data.roles?.[key]) {
					resetOriginalProfiles(key, initUserData, data, { payrollEnabled });
				}
			});
		}

		// Portal Web Access default value (Checkbox config with inversed)
		if (mode === "CREATE") {
			data.deactivated = !hasUsersAccessPermission;
			if (roles.includes(ROLE_DRIVER_KEY) && userTier === "TIER_STARTER") {
				data.deactivated = true;
			}
		}

		// Setting default driver type, that would allow to set some
		// other default values such as payroll
		if (data.driverProfile && !data.driverProfile.driver_type) {
			data.driverProfile.driver_type = "COMPANY_DRIVER";
		}
		// Always maintain driver status as active when creating new driver
		if (data.driverProfile && data?.roles?.role_driver && mode === "CREATE") {
			data.driverProfile.deleted = false;
		}

		// Values in default settings having low space values
		const driverTypeKey = (data.driverProfile?.driver_type ?? "COMPANY_DRIVER").toLowerCase();

		if (
			// Resetting deafaults for payroll
			payrollEnabled &&
			// But only in create mode
			mode === "CREATE" &&
			// Resetting driver payroll if type changed
			(driverTypeChanged ||
				// Or if driver role was selected
				(roleChanged && data?.roles?.role_driver && !formData?.roles?.role_driver)) &&
			// Checking if this driver type has default config
			payrollSettings.defaults?.driver?.[driverTypeKey]
		) {
			data.driverPayroll = {
				...data.driverPayroll,
				...payrollSettings.defaults.driver[driverTypeKey],
			};
			if (driverTypeChanged && !initialization) {
				showSnackbar(snackbar, "Payroll settings reset to default", "info");
			}
		}

		setFormSettings({
			role: roles[0],
			roleType: user.roleType,
			parent: data.parent,
			parentType: data.parentType,
			// Checking if secondary roles enabled to enable additional sections
			driverProfileAllowed: roles.includes(ROLE_DRIVER_KEY),
			dispatcherProfileAllowed: roles.includes(ROLE_DISPATCHER_KEY),
			// Profile and payroll archived status required to render fields
			accessDeactivated: data.deactivated,
			driverProfileArchived: roles.includes(ROLE_DRIVER_KEY) && !!data?.driverProfile?.deleted,
			userPayrollArchived:
				(roles.includes(ROLE_DRIVER_KEY) && !data?.driverPayroll?.enroll_payroll) ||
				(roles.includes(ROLE_DISPATCHER_KEY) && !data?.dispatcherPayroll?.enroll_payroll) ||
				(roles.includes(ROLE_INVESTOR_KEY) && !data?.investorPayroll?.enroll_payroll),
			userRoleSupportsPayroll:
				roles.includes(ROLE_DRIVER_KEY) || roles.includes(ROLE_DISPATCHER_KEY) || roles.includes(ROLE_INVESTOR_KEY),
			investorEnabled: roles.includes(ROLE_INVESTOR_KEY),
			// Saving permissions and roles configs to regenerated new form
			enabledPermissions: enabled,
			configsPermissions: config,
			// Some roles can be combined, providing list of roles that can be combined
			enabledRoles: allowedRoles,
			canBeDectivated,
			canBeEditEmail,
			roleChanged,
			// UI may change based on type of config
			multiUser: data?.users?.length > 1,
			// Viewer configs
			viewerCanEditRole,
			viewerCanEditAndAccessPayroll,
			viewerLikeSubAccount,
			// User Permissions
			hasUsersAccessPermission,
		});

		if (data.users?.length > 1 && data.driverProfile?.phone) {
			// Can not save phone numbers if adding multile drivers at the same time
			delete data.driverProfile.phone;
		}

		setFormData(data);
	};

	const handleFormChange = (model, key) => {
		// UI Workaround to avoid animation rendering issues
		setTimeout(() => {
			let data = { ...model };
			if (key === "roles") {
				updateFormSettings(data, { roleChanged: true });
			} else if (key === "driverProfile" && data[key].deleted !== formData[key].deleted) {
				updateFormSettings(data);
			} else if (key === "driverProfile" && data[key].driver_type !== formData[key].driver_type) {
				updateFormSettings(data, { driverTypeChanged: true });
			} else if (key === "users" && data[key]?.length !== formData[key]?.length) {
				updateFormSettings(data);
			} else if (key === "investorFuel" && data[key].deleted !== formData[key].deleted) {
				updateFormSettings(data);
			} else if (
				["driverPayroll", "investorPayroll", "dispatcherPayroll"].includes(key) &&
				data[key]?.enroll_payroll !== formData[key]?.enroll_payroll
			) {
				updateFormSettings(data);
			} else {
				setFormData(data);
			}
		}, 0);
	};

	const handleFormSubmin = async (model) => {
		try {
			setProcessingError(null);
			setProcessing(true);
			setIsPayrollTripsAffectedWarningDialogOpen(false);
			const data = postprocessFormData(model);

			const multientry = data?.length > 1;
			const portalAccess = !model.deactivated;

			if (mode !== "CREATE" && multientry) {
				showSnackbar(snackbar, "Edit mode does not support update of multiple users at the same time", "error");
				return;
			}

			if (getUserTier() === "TIER_STARTER" && portalAccess) {
				setPaywallDialog({
					open: true,
					dataIds: {
						type: "user_restriction",
						data: { callback: handleFormSubmin },
						extraData: { dataSend: { ...model } },
					},
				});
				return;
			}

			if (user.roleType === "EXTERNAL" && portalAccess) {
				const dataValidation = { portalAccess, nroUsers: data?.length, userId };
				const validation = await getUsersValidation(carrierId, dataValidation);
				if (validation.warning) {
					setUserWarningDialog({ open: true, message: validation.message });
					return;
				}
			}

			for (const index in data) {
				if (multientry) {
					setCounter({ index: parseInt(index), length: data?.length });
				}
				const item = data[index];
				if (mode === "EDIT" || mode === "ACTIVATE") {
					await updateCarriertUser(carrierId, userId, item, {
						ignorePayrollTripsAffectedWarning: ignorePayrollTripsAffectedWarning.current,
					});
				} else if (mode === "CREATE") {
					if (dataIds?.truckId && origin === "DRIVER" && dataIds?.driverType) {
						item.truckId = dataIds.truckId;
						item.driverType = dataIds.driverType;
					}
					await createCarriertUser(carrierId, item);
				}
			}

			if (native) window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_USER_LIST" }));

			const userData = await signInWithToken();
			if (userData) dispatch(setUserData(userData));

			dispatch(incrementDataRevision({ event: "profileRevision" }));
			dispatch(incrementDataRevision({ event: "dataRevision" }));
			showSnackbar(snackbar, "Done!", "success");
			ignorePayrollTripsAffectedWarning.current = false;
			onDone?.();
		} catch (err) {
			const tripsAffected = err?.errors?.[0]?.metadata?.trips;
			setPayrollTripsAffected(tripsAffected);
			if (tripsAffected?.length) {
				setIsPayrollTripsAffectedWarningDialogOpen(true);
			} else {
				setProcessingError(err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...");
				showSnackbar(snackbar, err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...", "error");
			}
		} finally {
			setCounter(null);
			setProcessing(false);
		}
	};

	const handleSteteChangeSubmin = async (model) => {
		try {
			setProcessingError(null);
			setProcessing(true);

			if (mode !== "DELETE") {
				showSnackbar(snackbar, "Invalid dialog state", "error");
				return;
			}

			await deactivateCarriertUser(carrierId, userId, model, { version: 2 });
			dispatch(incrementDataRevision({ event: "profileRevision" }));
			if (native) window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_USER_LIST" }));

			showSnackbar(snackbar, "Done!", "success");
			onDone?.();
		} catch (err) {
			setProcessingError(err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...");
			showSnackbar(snackbar, err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...", "error");
		} finally {
			setCounter(null);
			setProcessing(false);
		}
	};

	const onAcceptPayrollTripsAffectedWarning = () => {
		cleanPayrollTripsAffectedWarning();
		ignorePayrollTripsAffectedWarning.current = true;
		handleFormSubmin(formData);
	};

	const cleanPayrollTripsAffectedWarning = () => {
		ignorePayrollTripsAffectedWarning.current = false;
		setPayrollTripsAffected();
	};

	const onHandleSupportBtn = (open) => {
		setUserWarningDialog({ ...userWarningDialog, open: !open });
		setHelpRequestDialog(open);
	};

	const formContent = useMemo(
		() =>
			!formSettings
				? null
				: createUserEditFrom(formSettings, { payrollEnabled, invoiceEnabled, fuelEnabled, mode, origin }),
		[formSettings, payrollEnabled, invoiceEnabled, fuelEnabled, mode, origin]
	);

	const getProfileStatus = (active) => {
		return (
			<Typography className={"text-13 font-bold " + (active ? " text-green " : " text-red ")}>
				{!active && <Icon className="text-15 -mb-3 mr-2">remove_circle</Icon>}
				{active ? "Enabled" : "Inactive"}
			</Typography>
		);
	};

	const getTypeUser = (parentType, role) => {
		if (!parentType || !["role_owner", "role_dispatcher", "role_manager"].includes(role)) {
			return null;
		}

		const isParent = parentType === "PARENT";
		return (
			<Tooltip
				classes={{ tooltip: "drop-shadow-md bg-white border-1 border-grey min-w-300" }}
				title={
					<Typography className={"text-13 text-black py-10 px-10"}>
						{`This user was created by an ${isParent ? "parent" : "connected"} user (not possible to edit this user)`}
					</Typography>
				}
			>
				<Chip
					className={`mb-1 text-11 h-18 text-white ${isParent ? "bg-secondary" : "bg-green"}`}
					label={isParent ? "Parent" : "Connected"}
					size="small"
				/>
			</Tooltip>
		);
	};

	if (loadingError || loading) {
		// Hack to hide loading progress when openning compoenent with cached user data
		if (props.cache) return null;

		return (
			<div
				className={
					"flex w-full flex-col items-center justify-center " +
					(mode === "DELETE" ? " h-224 " : mode === "EDIT" || mode === "ACTIVATE" ? " h-320 " : " h-512 ")
				}
			>
				{loadingError ? (
					<Typography color="error" className="text-red text-13 font-light mx-20 py-6 md:pb-48">
						{loadingError}
					</Typography>
				) : loading ? (
					<Typography color="primary" className="text-13 font-light mx-20 py-6 md:pb-48">
						Loading...
					</Typography>
				) : null}
			</div>
		);
	}

	return (
		<div className={" relative " + (processing ? " opacity-60 pointer-events-none " : "")}>
			{mode === "DELETE" ? (
				<SmarthopFormView
					key="delete_user"
					content={formContent}
					noInitValidation={true}
					errors={processingError ? [{ type: "generic", message: processingError }] : null}
					dataIds={{ carrierId }}
					onDelete={handleSteteChangeSubmin}
					onCancel={() => onDone?.()}
				/>
			) : mode === "VIEW" ? (
				<div className="flex flex-col md:flex-row w-full md:space-x-40 px-20 mt-10">
					<div className="flex flex-col w-full">
						<DisplaySection
							title="Summary"
							classes={{ root: "mx-0 mb-20", divider: "mb-0" }}
							views={[getTypeUser(formSettings.parentType, formSettings.role)]}
						>
							<SmarthopFormView
								content={formContent.summary}
								data={formData}
								mode={"VIEW"}
								overrides={{
									group: { component: { variant: "vertical", classes: null } },
									ALL_EDITABLE: { component: { preview: { variant: "row" } } },
								}}
								dataIds={{ carrierId }}
							/>
						</DisplaySection>
						{formContent.driverProfile && (
							<DisplaySection
								title="Driver Profile"
								classes={{ root: "mx-0 mb-20", divider: "mb-0" }}
								views={[getProfileStatus(formSettings.driverProfileAllowed && !formSettings.driverProfileArchived)]}
							>
								<SmarthopFormView
									content={formContent.driverProfile}
									data={formData}
									mode={"VIEW"}
									overrides={{
										group: { component: { variant: "vertical", classes: null } },
										ALL_EDITABLE: { component: { preview: { variant: "row" } } },
									}}
									dataIds={{ carrierId }}
								/>
							</DisplaySection>
						)}
						{formContent.investorFuel && (
							<DisplaySection title="Investor Fuel" classes={{ root: "mx-0 mb-20", divider: "mb-0" }}>
								<SmarthopFormView
									content={formContent.investorFuel}
									data={formData}
									mode={"VIEW"}
									overrides={{
										group: { component: { variant: "vertical", classes: null } },
										ALL_EDITABLE: { component: { preview: { variant: "row" } } },
									}}
									dataIds={{ carrierId }}
								/>
							</DisplaySection>
						)}
					</div>
					<div className="flex flex-col w-full">
						{formContent.webProfile && (
							<DisplaySection
								title="Portal Access"
								classes={{ root: "mx-0 mb-20", divider: "mb-0" }}
								views={[getProfileStatus(!formSettings.accessDeactivated)]}
							>
								<SmarthopFormView
									content={formContent.webProfile}
									data={formData}
									mode={"VIEW"}
									overrides={{
										group: { component: { variant: "vertical", classes: null } },
										ALL_EDITABLE: { component: { preview: { variant: "row" } } },
									}}
									dataIds={{ carrierId }}
								/>
							</DisplaySection>
						)}
						{formContent.truckAccess && (
							<DisplaySection
								title="Truck Access"
								classes={{ root: "mx-0 mb-20", divider: "mb-0" }}
								views={[getProfileStatus(!formSettings.accessDeactivated)]}
							>
								<SmarthopFormView
									content={formContent.truckAccess}
									data={formData}
									mode={"VIEW"}
									overrides={{
										group: { component: { variant: "vertical", classes: null } },
										ALL_EDITABLE: { component: { preview: { variant: "row" } } },
									}}
									dataIds={{ carrierId }}
								/>
							</DisplaySection>
						)}
						{viewerCanEditAndAccessPayroll && formContent.driverPay && (
							<DisplaySection title="Driver Pay" classes={{ root: "mx-0 mb-20", divider: "mb-0" }}>
								<SmarthopFormView
									content={formContent.driverPay}
									data={formData}
									mode={"VIEW"}
									overrides={{
										group: { component: { variant: "vertical", classes: null } },
										ALL_EDITABLE: { component: { preview: { variant: "row" } } },
									}}
									dataIds={{ carrierId }}
								/>
							</DisplaySection>
						)}
						{viewerCanEditAndAccessPayroll && formContent.payrollProfile && (
							<DisplaySection
								title="Payroll Profile"
								classes={{ root: "mx-0 mb-20", divider: "mb-0" }}
								views={[getProfileStatus(!formSettings.userPayrollArchived)]}
							>
								<SmarthopFormView
									content={formContent.payrollProfile}
									data={formData}
									mode={"VIEW"}
									overrides={{
										group: { component: { variant: "vertical", classes: null } },
										ALL_EDITABLE: { component: { preview: { variant: "row" } } },
									}}
									dataIds={{ carrierId }}
								/>
							</DisplaySection>
						)}
					</div>
				</div>
			) : (
				<SmarthopFormView
					key="edit_user"
					content={formContent}
					noInitValidation={true}
					data={formData}
					errors={processingError ? [{ type: "generic", message: processingError }] : null}
					mode={props?.dataIds?.historyData ? "COMPARE" : mode !== "CREATE" ? "EDIT" : "CREATE"}
					dataIds={{ carrierId, accountId }}
					trackChangedFields={["ALL"]}
					onChangeCommitted={handleFormChange}
					onSubmit={handleFormSubmin}
				/>
			)}
			{counter && (
				<SmarthopConfirmDialog
					key={"counter"}
					open={true}
					message={`Processing ${parseInt(counter?.index + 1)} out of ${counter?.length}...`}
				/>
			)}
			{counter && (
				<SmarthopConfirmDialog
					key={"reactivate"}
					open={true}
					message={`Processing ${parseInt(counter?.index + 1)} out of ${counter?.length}...`}
				/>
			)}
			{payrollTripsAffected?.length && (
				<PayrollTripsAffectedWarningConfirmDialog
					trips={payrollTripsAffected}
					open={isPayrollTripsAffectedWarningDialogOpen}
					onClose={cleanPayrollTripsAffectedWarning}
					onAccept={onAcceptPayrollTripsAffectedWarning}
				/>
			)}

			<PaywallDialog data={paywallDialog} onHandlerPaywall={() => setPaywallDialog({})} />

			<SmarthopDialog
				open={userWarningDialog.open}
				title={"User Creation Warning"}
				onClose={() => setUserWarningDialog({ ...userWarningDialog, open: false })}
			>
				<div className="text-center mb-20">
					<Typography variant="subtitle1" className="leading-normal">
						{userWarningDialog.message}
					</Typography>
				</div>
				<Button className="w-full mt-5" variant="contained" color="secondary" onClick={() => onHandleSupportBtn(true)}>
					Customer Support
				</Button>
			</SmarthopDialog>

			<HelpRequestDialog open={helpRequestDialog} setClose={() => onHandleSupportBtn(false)} />
		</div>
	);
}

export default CarrierUsersEditView;
