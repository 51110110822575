import _ from "@lodash";

const ROLE_OWNER_KEY = "role_owner";
const ROLE_MANAGER_KEY = "role_manager";
const ROLE_DISPATCHER_KEY = "role_dispatcher";
const ROLE_INVESTOR_KEY = "role_investor";
const ROLE_DRIVER_KEY = "role_driver";

const PERMISSION_BILLING_ACCESS = "permission_billing_access";
const PERMISSION_USER_ACCESS = "permission_users_access";
const PERMISSION_FLEET_CONFIG_ACCESS = "permission_fleet_config_access";
const PERMISSION_PAYROLL_ACCESS = "permission_payroll_access";
const PERMISSION_INVOICE_ACCESS = "permission_invoice_access";

const PERMISSION_SEARCH_ACCESS_ON = "permission_search_on";
const PERMISSION_INSTANT_BOOK_ON = "permission_book_on";
const PERMISSION_TRIPS_ACCESS = "permission_trips_access";
const PERMISSION_RATES_ON = "permission_rates_on";
const PERMISSION_MESSAGING_ON = "permission_messaging_on";
const PERMISSION_PERFORMANCE_ACCESS = "permission_performance_access";

const LEVEL_NO_ACCESS = "no_access";
const LEVEL_VIEW = "viewer";
const LEVEL_EDIT = "editor";
const LEVEL_ADMIN = "admin";
const PERMISSIONS_PRIORITY = [LEVEL_ADMIN, LEVEL_EDIT, LEVEL_VIEW, LEVEL_NO_ACCESS];

const ROLES_PRIORITY = [ROLE_OWNER_KEY, ROLE_MANAGER_KEY, ROLE_DISPATCHER_KEY, ROLE_INVESTOR_KEY, ROLE_DRIVER_KEY];
const ROLES_LABLES = {
	[ROLE_OWNER_KEY]: "Owner",
	[ROLE_MANAGER_KEY]: "Manager",
	[ROLE_DISPATCHER_KEY]: "Dispatcher",
	[ROLE_INVESTOR_KEY]: "Investor",
	[ROLE_DRIVER_KEY]: "Driver",
};

const ROLES_BY_VALUE = {
	[ROLE_OWNER_KEY]: "CARRIER_OWNER",
	[ROLE_MANAGER_KEY]: "CARRIER_MANAGER",
	[ROLE_DISPATCHER_KEY]: "CARRIER_DISPATCHER",
	[ROLE_DRIVER_KEY]: "CARRIER_DRIVER",
};

const ROLES_BY_PERMISSION = {
	[ROLE_INVESTOR_KEY]: "permission_investor_on",
	[ROLE_DRIVER_KEY]: "permission_driver_on",
};

const ROLES_CONFIGS = {
	[ROLE_OWNER_KEY]: {
		permissionsSchema: [
			{ key: PERMISSION_BILLING_ACCESS, levels: [LEVEL_EDIT], default: true, disabled: true },
			{ key: PERMISSION_USER_ACCESS, levels: ["BOOL_TRUE"], default: true, disabled: true },
			{ key: PERMISSION_FLEET_CONFIG_ACCESS, levels: ["BOOL_TRUE"], default: true, disabled: true },
			{ key: PERMISSION_PAYROLL_ACCESS, levels: [LEVEL_VIEW, LEVEL_EDIT, LEVEL_ADMIN], default: true, disabled: true },
			{ key: PERMISSION_INVOICE_ACCESS, levels: [LEVEL_VIEW, LEVEL_EDIT], default: true, disabled: true },
			{ key: PERMISSION_SEARCH_ACCESS_ON, levels: ["BOOL_TRUE"], default: true, disabled: true },
			{ key: PERMISSION_MESSAGING_ON, levels: ["BOOL_TRUE"], default: true, disabled: true },
			{ key: PERMISSION_INSTANT_BOOK_ON, levels: ["BOOL_TRUE"], default: true, disabled: true },
			{ key: PERMISSION_TRIPS_ACCESS, levels: [LEVEL_VIEW, LEVEL_EDIT], default: true, disabled: true },
			{ key: PERMISSION_RATES_ON, levels: ["BOOL_TRUE"], default: true, disabled: true },
			{ key: PERMISSION_PERFORMANCE_ACCESS, levels: [LEVEL_VIEW], default: true, disabled: true },
		],
		allowedExtraRoles: [ROLE_DRIVER_KEY],
	},
	[ROLE_MANAGER_KEY]: {
		permissionsSchema: [
			{ key: PERMISSION_USER_ACCESS, levels: ["BOOL_TRUE"], default: true, disabled: false },
			{ key: PERMISSION_BILLING_ACCESS, levels: [LEVEL_EDIT], default: false, disabled: false },
			{ key: PERMISSION_FLEET_CONFIG_ACCESS, levels: ["BOOL_TRUE"], default: true, disabled: false },
			{ key: PERMISSION_PAYROLL_ACCESS, levels: [LEVEL_VIEW, LEVEL_EDIT, LEVEL_ADMIN], default: true },
			{ key: PERMISSION_INVOICE_ACCESS, levels: [LEVEL_VIEW, LEVEL_EDIT], default: true },
			{ key: PERMISSION_SEARCH_ACCESS_ON, levels: ["BOOL_TRUE"], default: true, disabled: false },
			{ key: PERMISSION_MESSAGING_ON, levels: ["BOOL_TRUE"], default: true, disabled: false },
			{ key: PERMISSION_INSTANT_BOOK_ON, levels: ["BOOL_TRUE"], default: true, disabled: false },
			{ key: PERMISSION_TRIPS_ACCESS, levels: [LEVEL_VIEW, LEVEL_EDIT], default: true, disabled: false },
			{ key: PERMISSION_RATES_ON, levels: ["BOOL_TRUE"], default: true },
			{ key: PERMISSION_PERFORMANCE_ACCESS, levels: [LEVEL_VIEW], default: true, disabled: true },
		],
		allowedExtraRoles: [ROLE_DRIVER_KEY],
	},
	[ROLE_DISPATCHER_KEY]: {
		permissionsSchema: [
			{ key: PERMISSION_FLEET_CONFIG_ACCESS, levels: [LEVEL_EDIT], default: true, disabled: true },
			{
				key: PERMISSION_PAYROLL_ACCESS,
				levels: [LEVEL_VIEW],
				default: true,
				disabled: true,
				disclaimer: "Limited to this user payroll when enrolled",
			},
			{ key: PERMISSION_INVOICE_ACCESS, levels: [LEVEL_VIEW, LEVEL_EDIT] },
			{ key: PERMISSION_SEARCH_ACCESS_ON, levels: ["BOOL_TRUE"], default: true, disabled: true },
			{ key: PERMISSION_MESSAGING_ON, levels: ["BOOL_TRUE"], default: true, disabled: true },
			{ key: PERMISSION_INSTANT_BOOK_ON, levels: ["BOOL_TRUE"], default: true, disabled: true },
			{ key: PERMISSION_TRIPS_ACCESS, levels: [LEVEL_VIEW, LEVEL_EDIT], default: true, disabled: true },
			{ key: PERMISSION_RATES_ON, levels: ["BOOL_TRUE"], default: true, disabled: true },
			{ key: PERMISSION_PERFORMANCE_ACCESS, levels: [LEVEL_VIEW], default: true, disabled: true },
		],
	},
	[ROLE_INVESTOR_KEY]: {
		permissionsSchema: [
			{
				key: PERMISSION_PAYROLL_ACCESS,
				levels: [LEVEL_VIEW],
				default: true,
				disabled: true,
				disclaimer: "Limited to this user payroll when enrolled",
			},
			{ key: PERMISSION_SEARCH_ACCESS_ON, levels: ["BOOL_TRUE"], default: false },
			{ key: PERMISSION_MESSAGING_ON, levels: ["BOOL_TRUE"], default: false },
			{ key: PERMISSION_INSTANT_BOOK_ON, levels: ["BOOL_TRUE"], default: false },
			{
				key: PERMISSION_TRIPS_ACCESS,
				levels: [LEVEL_VIEW, LEVEL_EDIT],
				default: LEVEL_VIEW,
				disabled: false,
				disclaimer: "Limited to this user assigned trips",
			},
			{ key: PERMISSION_RATES_ON, levels: ["BOOL_TRUE"], default: true },
			{
				key: PERMISSION_PERFORMANCE_ACCESS,
				levels: [LEVEL_VIEW],
				default: true,
				disabled: true,
				disclaimer: "Limited to this user assigned trucks analytics",
			},
		],
	},
	[ROLE_DRIVER_KEY]: {
		permissionsSchema: [
			{
				key: PERMISSION_PAYROLL_ACCESS,
				levels: [LEVEL_VIEW],
				default: true,
				disabled: true,
				disclaimer: "Limited to this user payroll when enrolled",
			},
			{ key: PERMISSION_SEARCH_ACCESS_ON, levels: ["BOOL_TRUE"] },
			{ key: PERMISSION_MESSAGING_ON, levels: ["BOOL_TRUE"] },
			{ key: PERMISSION_INSTANT_BOOK_ON, levels: ["BOOL_TRUE"] },
			{
				key: PERMISSION_TRIPS_ACCESS,
				levels: [LEVEL_VIEW, LEVEL_EDIT],
				default: LEVEL_VIEW,
				disabled: false,
				disclaimer: "Limited to this user assigned trips",
			},
			{
				key: PERMISSION_TRIPS_ACCESS,
				levels: [LEVEL_EDIT],
				disabled: true,
			},
			{ key: PERMISSION_RATES_ON, levels: ["BOOL_TRUE"] },
		],
		allowedExtraRoles: [ROLE_OWNER_KEY, ROLE_MANAGER_KEY],
	},
};

const getUserRoles = (flags) => {
	const roles = [];
	ROLES_PRIORITY.forEach((key) => {
		if (!!flags?.[key]) {
			roles.push(key);
		}
	});
	return roles;
};

const getUserRolesLabel = (flags) => {
	let text = "";
	ROLES_PRIORITY.forEach((role) => {
		if (flags?.[role]) {
			if (text.length > 0) text += ", ";
			text += ROLES_LABLES[role];
		}
	});
	return text;
};

const getUserPermissions = (flags) => {
	const permissions = {};
	Object.keys(flags ?? {}).forEach((fKey) => {
		const fValue = !!flags[fKey];
		const [key, level] = fKey.split("__");
		if (!level) throw new Error("Unsupported permission flag format");

		if (level === "BOOL_TRUE") {
			// Legacy permissions
			permissions[key] = fValue;
		} else {
			const value = fValue ? level : LEVEL_NO_ACCESS;
			const savedPermissionRank = permissions[key] ? PERMISSIONS_PRIORITY.indexOf(permissions[key]) : null;
			const valuePermissionRank = PERMISSIONS_PRIORITY.indexOf(value);

			if (savedPermissionRank === -1 || valuePermissionRank === -1) {
				if (!level) throw new Error("Unsupported permission level");
			}
			if (!savedPermissionRank || valuePermissionRank < savedPermissionRank) {
				permissions[key] = value;
			}
		}
	});
	return permissions;
};

const getDefaultPermissions = (role, { payrollEnabled, invoiceEnabled, billingEnabled } = {}) => {
	const permissions = ROLES_CONFIGS[role]?.permissionsSchema;
	const values = {};
	const config = {};
	const enabled = [];
	permissions?.forEach((permission) => {
		if (!permission.key) return;
		if (!billingEnabled && permission.key === PERMISSION_BILLING_ACCESS) permission.disabled = true;
		permission.levels?.forEach((level) => {
			if (!payrollEnabled && permission.key === PERMISSION_PAYROLL_ACCESS) {
				return;
			}
			if (!invoiceEnabled && permission.key === PERMISSION_INVOICE_ACCESS) {
				return;
			}
			if (!permission.disabled) {
				enabled.push(permission.key + "__" + level);
			}
			values[permission.key + "__" + level] =
				typeof permission.default === "string" ? level === permission.default : permission.default;
			config[permission.key + "__" + level] = { disclaimer: permission.disclaimer };
		});
	});

	return { values, config, enabled };
};

const getAllowedRoles = (role) => {
	if (!role) return null;
	const allowedRoles = ROLES_CONFIGS[role]?.allowedExtraRoles;
	return [role, ...(allowedRoles ?? [])];
};

const permissionEdit = (key) => key + "__" + LEVEL_EDIT;
const permissionView = (key) => key + "__" + LEVEL_VIEW;
const permissionAdmin = (key) => key + "__" + LEVEL_ADMIN;
const permissionBoolTrue = (key) => key + "__BOOL_TRUE";

const resetPermissions = (data) => {
	if (!data) return;
	const copy = { ...data };
	Object.keys(data).forEach((key) => {
		if (key.startsWith("permission_")) {
			copy[key] = false;
		}
	});
	return copy;
};

const getPermissionsCounters = (permissions) => {
	let editCount = 0;
	let viewCount = 0;

	Object.keys(permissions ?? {}).forEach((key) => {
		if (permissions[key]) {
			if (key.includes("__view") || key === PERMISSION_RATES_ON) {
				viewCount += 1;
			} else {
				editCount += 1;
			}
		}
	});
	return { editCount, viewCount };
};

const getTruckAccessType = (access) => {
	const accessType = access.accessType ?? access.truckAccess;
	const type = accessType === "FULL" || accessType === "FULL_TRUCK" ? "Full" : "Partial";
	return type;
};

const preprocessFormData = (data, { payrollEnabled, invoiceEnabled, billingEnabled } = {}) => {
	if (!data) return;

	const result = { users: [], roles: {}, permissions: {} };
	if (data.user) {
		result.deactivated = data.user.deactivated;
		result.sms_notify_opt_in = data.user.sms_notify_opt_in;
		result.parent = data.user.parent;
		result.parentType = data.user.parentType;
		result.accessAt = data.user.accessAt;
		result.users.push({
			first_name: data.user.first_name,
			last_name: data.user.last_name,
			email: data.user.email,
		});
	}

	// Looking for main role, which would define structure of the permissions
	let mainRoleKey = Object.keys(ROLES_BY_VALUE).find((key) => {
		if (ROLES_BY_VALUE[key] === data.user.role) {
			// Saving that flag
			result.roles[key] = true;
			return true;
		}
		return false;
	});

	// Some roles are permission based, and can be used in combination with a main role
	Object.keys(ROLES_BY_PERMISSION).forEach((key) => {
		const permKey = ROLES_BY_PERMISSION[key];
		if (!!data.permissions[permKey]) {
			// If we have not found any role by role field value we need to look by permission
			if (!mainRoleKey) mainRoleKey = key;
			// Saving that role
			result.roles[key] = true;
		}
	});

	// Configuring basic permission schema
	let { values: permissionsFlags, enabled } = getDefaultPermissions(mainRoleKey, {
		payrollEnabled,
		invoiceEnabled,
		billingEnabled,
	});
	Object.keys(permissionsFlags).forEach((nKeyValue) => {
		if (enabled.includes(nKeyValue)) {
			let [pKey, pValue] = nKeyValue.split("__");
			if (pValue === "viewer") {
				permissionsFlags[nKeyValue] =
					data.permissions[pKey] === "viewer" ||
					data.permissions[pKey] === "editor" ||
					data.permissions[pKey] === "admin";
			} else if (pValue === "editor") {
				permissionsFlags[nKeyValue] = data.permissions[pKey] === "editor" || data.permissions[pKey] === "admin";
			} else if (pValue === "admin") {
				permissionsFlags[nKeyValue] = data.permissions[pKey] === "admin";
			} else if (pValue === "BOOL_TRUE") {
				permissionsFlags[nKeyValue] = data.permissions[pKey];
			} else {
				permissionsFlags[nKeyValue] = pValue;
			}
		}
	});
	result.permissions = permissionsFlags;

	// Configuring driver profile and payroll / pay
	if (result.roles[ROLE_DRIVER_KEY]) {
		result.driverProfile = data.driver?.settings ? { ...data.driver?.settings } : {};
		result.driverProfile.deleted = !data.driver?.enabled;
		if (payrollEnabled) {
			result.driverPayroll = data.payroll?.settings ? { ...data.payroll?.settings } : {};
			result.driverPayroll.enroll_payroll = !!data.payroll?.enabled;
		} else {
			result.driverPay = data.driver?.settings ?? {};
		}
	}

	// Configuration Truck Access
	if (result.roles[ROLE_DISPATCHER_KEY]) {
		result.truckAccess = data.access?.settings ?? {};
	}

	// Configuring disaptcher payroll
	if (result.roles[ROLE_DISPATCHER_KEY]) {
		result.dispatcherPayroll = data.payroll?.settings ? { ...data.payroll?.settings } : {};
		result.dispatcherPayroll.enroll_payroll = !!data.payroll?.enabled;
	}

	// Configuring investor payroll
	result.investorFuel = data.investor?.settings ? { ...data.investor?.settings } : {};
	if (result.roles[ROLE_INVESTOR_KEY]) {
		result.investorPayroll = data.payroll?.settings ? { ...data.payroll?.settings, ...data.investor?.settings } : {};
		result.investorPayroll.enroll_payroll = !!data.payroll?.enabled;
	}

	return result;
};

const resetOriginalProfiles = (key, initData, data, { payrollEnabled } = {}) => {
	if (key === ROLE_DRIVER_KEY) {
		data.driverProfile = initData.driver?.settings ?? {};
		if (payrollEnabled) {
			data.driverPayroll = initData.payroll?.settings ?? {};
			data.driverPayroll.enabled = initData.payroll?.enabled;
		} else {
			data.driverPay = initData.payroll?.settings ?? {};
		}
	} else if (key === ROLE_DISPATCHER_KEY) {
		data.dispatcherPayroll = initData.payroll?.settings ?? {};
		data.dispatcherPayroll.enroll_payroll = !!initData.payroll?.enabled;
	} else if (key === ROLE_INVESTOR_KEY) {
		data.investorFuel = initData.investor?.settings ?? {};
		data.investorPayroll = initData.payroll?.settings ?? {};
		data.investorPayroll.enroll_payroll = !!initData.payroll?.enabled;
	}
};

const postprocessFormData = (data) => {
	const roles = getUserRoles(data.roles);
	const permissions = getUserPermissions(data.permissions);

	// Main role is selected based on priority
	const mainRoleKey = roles[0];

	let driver = { settings: {}, enabled: false };
	let payroll = { settings: {}, enabled: false };
	let investor = { settings: {}, enabled: false };
	let access = { settings: {}, enabled: false };

	if (roles.includes(ROLE_DRIVER_KEY)) {
		// Processing driver payroll and profile
		if (data.driverPayroll) {
			driver = {
				settings: { ...(data.driverProfile ?? {}), ...(data.driverPayroll ?? {}) },
				enabled: !data.driverProfile.deleted,
			};
			payroll = { settings: data.driverPayroll, enabled: !!data.driverPayroll.enroll_payroll };
		} else {
			driver = {
				settings: { ...(data.driverProfile ?? {}), ...(data.driverPay ?? {}) },
				enabled: !data.driverProfile.deleted,
			};
			payroll = { settings: {}, enabled: false };
		}
	}

	if (roles.includes(ROLE_DISPATCHER_KEY)) {
		if (data.dispatcherPayroll) {
			// Processing disaptcher payroll
			const settings = data.dispatcherPayroll;
			const enabled = !!settings.enroll_payroll;
			delete settings.enroll_payroll;
			payroll = { settings, enabled };
		}

		if (data.truckAccess) {
			// Processing Truck Access
			const settings = data.truckAccess;
			const enabled = true;
			access = { settings, enabled };
		}
	}

	if (roles.includes(ROLE_INVESTOR_KEY)) {
		// Processing investor payroll
		const settings = { ...(data.investorPayroll ?? {}), ...(data.investorFuel ?? {}) };
		payroll = { settings, enabled: !!settings.enroll_payroll };
		investor = { settings, enabled: true };
		delete payroll.settings.enroll_payroll;
	}

	const result = [];
	const users = _.cloneDeep(data.users);
	users.forEach((user) => {
		// If main role is not connected to any actual role, we should use generic role
		user.role = ROLES_BY_VALUE[mainRoleKey] ?? "CARRIER_GENERIC";
		if (data?.sms_notify_opt_in) {
			user.sms_notify_opt_in = data.sms_notify_opt_in;
		}
		// Some roles are permission based, so we need to set those permissions to true
		Object.keys(ROLES_BY_PERMISSION).forEach((key) => {
			if (roles.includes(key)) {
				const permKey = ROLES_BY_PERMISSION[key];
				permissions[permKey] = true;
			}
		});

		user.deactivated = data.deactivated;
		result.push({ user, permissions, access, driver, payroll, investor });
	});

	return result;
};

export {
	ROLE_OWNER_KEY,
	ROLE_MANAGER_KEY,
	ROLE_DISPATCHER_KEY,
	ROLE_INVESTOR_KEY,
	ROLE_DRIVER_KEY,
	PERMISSION_BILLING_ACCESS,
	PERMISSION_USER_ACCESS,
	PERMISSION_FLEET_CONFIG_ACCESS,
	PERMISSION_PAYROLL_ACCESS,
	PERMISSION_INVOICE_ACCESS,
	PERMISSION_SEARCH_ACCESS_ON,
	PERMISSION_MESSAGING_ON,
	PERMISSION_INSTANT_BOOK_ON,
	PERMISSION_TRIPS_ACCESS,
	PERMISSION_RATES_ON,
	PERMISSION_PERFORMANCE_ACCESS,
	ROLES_CONFIGS,
	ROLES_PRIORITY,
	ROLES_LABLES,
	permissionEdit,
	permissionView,
	permissionAdmin,
	permissionBoolTrue,
	resetPermissions,
	resetOriginalProfiles,
	getDefaultPermissions,
	getAllowedRoles,
	getUserRoles,
	getUserRolesLabel,
	getPermissionsCounters,
	getTruckAccessType,
	preprocessFormData,
	postprocessFormData,
};
