import { useSnackbar } from "notistack";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageWrapper from "app/main/common/PageWrapper";
import {
	setCurrentSearch,
	fetchSearchesSaved,
	fetchSearchResults,
	removeSearch,
	cleanErrors,
	FINISHED_STATES,
} from "app/store/search/searchV3Slice";

import { closeMenuPanel } from "app/store/tools/menuSlice";

import SearchVersionSwitch from "./components/SearchVersionSwitch";
import RequestPanel from "./components/RequestPanel";
import SearchStatusBar from "./components/SearchStatusBar";
import SearchResults from "./components/SearchResults";
import SearchTabs from "./components/SearchTabs";

import { showSnackbar } from "app/main/utils/snackbarUtil";
import { clearCredentials } from "app/store/search/credentialsSlice";
import { isEnabled } from "app/services/featureStorageService";

const saveSearches = async (searches) => {
	let searchesLocalStorage = {};

	for (const key in searches) {
		searchesLocalStorage[key] = {
			filters: searches[key].filters,
			softFilters: searches[key].softFilters,
			order: searches[key].order,
		};
	}
	if (searchesLocalStorage) localStorage.setItem("SEARCH_LIST", JSON.stringify(searchesLocalStorage));
};

function SearchPage(props) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const restrictions = ["ONBOARDING", "TIER_LIMITED"];

	const isNativeEnv = window?.location?.href?.includes("/native/");
	const searches = useSelector(({ search }) => search.searchV3Slice.searches);
	const searchesOrder = useSelector(({ search }) => search.searchV3Slice.searchesOrder);
	const currentSearchId = useSelector(({ search }) => search.searchV3Slice.currentSearch);
	const errors = useSelector(({ search }) => search.searchV3Slice.errors);
	const user = useSelector(({ auth }) => auth.user);
	const socket = useSelector(({ socket }) => socket.connection.socket);
	const currentSearch = searches[currentSearchId];
	const newLoads = useRef(0);
	const intervalIdRef = useRef(null);

	const [mobile, setMobile] = useState(window.innerWidth < 960);
	const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1800);
	const [isScrolled, setIsScrolled] = useState(false);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 960 && !mobile) {
				setMobile(true);
			} else if (window.innerWidth > 960 && mobile) {
				setMobile(false);
			}
			if (window.innerWidth < 1600 && window.innerWidth > 960 && !smallScreen) {
				setSmallScreen(true);
			} else if ((window.innerWidth > 1600 || window.innerWidth < 960) && smallScreen) {
				setSmallScreen(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	useEffect(() => {
		return dispatch(fetchSearchesSaved());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (errors?.[0]) {
			showSnackbar(snackbar, errors[0].message, "error", 5000);
			dispatch(cleanErrors());
			window.history.replaceState(null, "", "/search");
		}
		// eslint-disable-next-line
	}, [errors]);

	//Save searches on localStorage
	useEffect(() => {
		saveSearches(searches);
	}, [searches]);

	//Send keepalive for all active searches every minute
	useEffect(() => {
		const sendAliveMessage = () => {
			let activeSearches = [];
			Object.keys(searches).forEach((searchId) => {
				if (searchId !== "EMPTY" && searches[searchId].state !== "EXPIRED") activeSearches.push(searchId);
			});
			if (activeSearches.length > 0) {
				socket.emit("message", { type: "SEARCH_ALIVE", data: activeSearches });
				console.log(`[SearchPage]Alive socket sent for searches ${activeSearches}`);
			}
		};

		const handleVisibilityChange = () => {
			if (document.hidden || Object.keys(searches).length === 1) {
				console.log("[SearchPage]Alive stopped due view is hidden");
				clearInterval(intervalIdRef.current);
				intervalIdRef.current = null;
			} else if (intervalIdRef.current === null && Object.keys(searches).length > 1) {
				console.log("[SearchPage]Alive started due view is visible");
				//Check availability every 2 minutes
				intervalIdRef.current = setInterval(sendAliveMessage, 30000);
			}
		};

		if (!document.hidden && intervalIdRef.current === null && Object.keys(searches).length > 1) {
			console.log("[SearchPage]Alive started");
			intervalIdRef.current = setInterval(sendAliveMessage, 60000);
		}

		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			clearInterval(intervalIdRef.current);
			intervalIdRef.current = null;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Object.keys(searches).length]);

	//Handle new loads notification
	useEffect(() => {
		if (currentSearch?.stats?.new === 0) return;
		if (newLoads.current < currentSearch?.stats?.new) {
			showSnackbar(snackbar, `Found ${currentSearch?.stats?.new - newLoads.current} new load(s)!`, "info", 6000);
		}
		newLoads.current = currentSearch?.stats?.new;
		// eslint-disable-next-line
	}, [currentSearch?.stats?.new]);

	const handleResultsFetch = (searchId) => {
		const search = searches[searchId];
		dispatch(setCurrentSearch({ searchId: searchId, userId: user._id }));
		if (searchId !== "EMPTY") {
			if (
				!FINISHED_STATES.includes(search?.state) ||
				!search?.results ||
				(search?.results && Object.keys(search?.results) === 0)
			) {
				dispatch(fetchSearchResults({ searchId: searchId, userId: user._id, options: { setAsCurrent: true } }));
			}
		}
	};

	function handleUrlChange() {
		const urlParams = new URLSearchParams(window.location.search);
		const urlSearchId = urlParams.get("id");
		if (urlSearchId) {
			handleResultsFetch(urlSearchId);
		} else {
			dispatch(setCurrentSearch());
		}
	}

	// Checking search ID in URL
	useEffect(() => {
		handleUrlChange();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Handle url refresh
	useEffect(() => {
		if (currentSearchId === "EMPTY") {
			window.history.replaceState(null, "", "/search");
		} else {
			window.history.replaceState(null, "", `/search?id=${currentSearchId}`);
		}
	}, [currentSearchId]);

	useEffect(() => {
		//New pagination process doesn't require a pull process for results
		if (isEnabled("SEARCH_PAGINATION_NEW")) return;

		if (
			!currentSearch?.attempts === undefined ||
			currentSearchId === "EMPTY" ||
			(currentSearch?.processing && currentSearch?.attempts === undefined)
		)
			return;

		//Fetch results until the search state is finished
		if (FINISHED_STATES.includes(currentSearch?.state) || !currentSearch?.attempts > 10) return;
		let timer;
		let delay =
			currentSearch?.attempts === 1
				? 250
				: currentSearch?.attempts < 4
				? 1000
				: currentSearch?.attempts < 10
				? 2000
				: 3000;
		timer = setTimeout(() => dispatch(fetchSearchResults({ searchId: currentSearchId, userId: user._id })), delay);

		return () => {
			clearTimeout(timer);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSearch?.attempts, currentSearch?.state, currentSearchId, user._id]);

	const onSelectTab = (e, id) => {
		e.stopPropagation();
		dispatch(closeMenuPanel());
		dispatch(clearCredentials());
		handleResultsFetch(id);
	};

	const onCloseTab = (e, searchId) => {
		e.stopPropagation();
		dispatch(removeSearch(searchId));
		dispatch(closeMenuPanel());
		window.history.replaceState(null, "", "/search");
	};

	return (
		<PageWrapper
			restrictions={restrictions}
			resource={{ onboarding: "SEARCH_ONBOARDING", tutorial: "SEARCH_TUTORIAL" }}
			classes={{
				root: "overflow-hidden  ",
				content: "flex w-full ",
			}}
			isView={true}
			noPadding={true}
		>
			<div className="flex flex-col flex-auto min-h-0 relative z-10">
				{!mobile && (
					<SearchTabs
						currentSearchId={currentSearchId}
						searches={searches}
						searchesOrder={searchesOrder}
						onSelectTab={onSelectTab}
						onCloseTab={onCloseTab}
					/>
				)}
				<div
					className={` flex flex-col bg-white transition-all duration-500 ${
						!mobile || (mobile && !isScrolled) ? "opacity-100 h-auto" : "opacity-0 h-0 overflow-hidden"
					}`}
				>
					{mobile && !isNativeEnv && <SearchVersionSwitch isMobile={true} key="searchV3_switch" />}
					<RequestPanel id={currentSearchId} processing={currentSearch?.processing} />
					<div className={"flex flex-col w-full bg-grey-200"} style={{ height: "1px" }} />
					<SearchStatusBar id={currentSearchId} mobile={mobile} />
				</div>
				<SearchResults mobile={mobile} onScroll={setIsScrolled} />
			</div>
		</PageWrapper>
	);
}

export default SearchPage;
