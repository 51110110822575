const userAxleForm = {
	urlGET: "api/profile/carriers/:carrierId/drivers/:driverId/details",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "axleId",
								type: "text",
								label: "Axle ID",
							},
							{
								key: "axleName",
								type: "text",
								label: "Axle Name",
							},
							{
								key: "axleImported",
								type: "text",
								label: "Axle Imported",
							},
						],
					},
				},
			],
		},
	},
};

export default userAxleForm;
