import clsx from "clsx";
import uniqid from "uniqid";
import { useSelector } from "react-redux";
import { useState, forwardRef, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

import SmarthopDialogViewContainer from "@smarthop/views/SmarthopDialogViewContainer";
import SmarthopDialogViewLoadingStub from "@smarthop/views/SmarthopDialogViewLoadingStub";

import { openFormDialog } from "app/store/tools/formDialogSlice";
import { getTripFiles } from "app/services/tripsServices";
import { getUserTier } from "app/services/LoginService";
import { isEnabled } from "app/services/featureStorageService";

// Public Services
import { getTripFilesPublic } from "app/services/publicServices";
import FilesView from "app/main/files/FilesView";

import { processTierRestrictions } from "app/main/utils/rolesUtils";

const TripFilesForm = forwardRef(({ nativeMobile, dataIds, setTitle, setSize }, ref) => {
	const dispatch = useDispatch();

	const filesRevision = useSelector(({ tools }) => tools.revision["filesRevision"]);

	const [data, setData] = useState({});
	const [fileLoading, setFileLoading] = useState(false);
	const [selectedLink, setSelectedLink] = useState(null);
	const [initialize, setInitialize] = useState(false);
	const [loading, setLoading] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [errorFiles, setErrorFiles] = useState(null);

	const userTier = getUserTier();
	const fileNameId = uniqid("file_id_");
	const fileInputRef = useRef();

	const userId = dataIds?.userId;
	const carrierId = dataIds?.carrierId;
	const tripId = dataIds?.tripId;
	const isPublicView = dataIds?.public ?? false;

	const isNative = window?.ReactNativeWebView ?? false;
	const processRestrictions = processTierRestrictions({ restrictions: ["TIER_PROFESSIONAL"], tier: userTier });
	const isEnabledFilesAnalysis = isPublicView
		? dataIds.showFilesAnalysis
		: isEnabled("ANALIZE_FILES_UPLOADED") && processRestrictions;

	useEffect(() => {
		if (!data?.data?.load_id) {
			setTitle?.("Edit Trip Files");
		} else {
			setTitle?.(`Edit Trip Files - Load ID ${data?.data?.load_id} `);
		}
		setSize?.("max-w-3xl");
		// eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		if (!carrierId || !tripId) return;

		const processFlag = initialize || data.length > 0 ? false : true;
		setProcessing(processFlag);

		const serviceFunction = isPublicView ? getTripFilesPublic : getTripFiles;
		serviceFunction(carrierId, tripId)
			.then((res) => {
				setData(res?.pod_files);
				setProcessing(false);
				setInitialize(true);
			})
			.catch((e) => {
				setErrorFiles(e.response?.data?.errors?.[0]?.message ?? e.message ?? "Something went wrong...");
				setProcessing(false);
				setInitialize(true);
			});

		// eslint-disable-next-line
	}, [carrierId, tripId, isPublicView, filesRevision]);

	const openPaywallView = () => {
		dispatch(
			openFormDialog({
				viewId: "PAYWALL_VIEW",
				dataIds: {
					type: "automate_functionality_restriction",
					data: { dataIdsInherited: dataIds, callbackKey: "UPLOAD_FILES_FORM" },
				},
			})
		);
	};

	if (processing) {
		return <SmarthopDialogViewLoadingStub nativeMobile={nativeMobile} loading={processing} />;
	}

	const isImage = ["png", "jpg", "jpeg"].includes(selectedLink?.type);
	return (
		<>
			<SmarthopDialogViewContainer
				nativeMobile={nativeMobile}
				processing={loading}
				sideComponents={[
					{
						key: "FILES_PREVIEW",
						type: "PREVIEW",
						tabKeys: ["DOCUMENTS"],
						hideOnMobile: true,
						ratio: "5/12",
						component: (
							<div className={`flex flex-col h-full w-full bg-grey-100 items-center `}>
								{!selectedLink ? (
									<Typography className="absolute top-2/4" color="textSecondary">
										No Preview File
									</Typography>
								) : fileLoading ? (
									<div className={clsx("flex w-full h-full items-center justify-center border-1 h-full")}>
										<CircularProgress className="text-grey-400" />
									</div>
								) : (
									<CardMedia
										component={isImage ? "img" : "iframe"}
										className={
											"flex relative bg-grey-200 overflow-hidden " + (isImage ? "w-full mt-10" : "w-full h-full")
										}
										image={selectedLink?.link}
										onLoad={() => {
											setFileLoading(false);
										}}
									/>
								)}
							</div>
						),
					},
				]}
				tabComponents={[
					{
						key: "DOCUMENTS",
						title: "Documents",
						component: (
							<div className="flex flex-col justify-between w-full">
								<div className="flex flex-col w-full">
									<FilesView
										data={data}
										type={"pod_files"}
										uploadConfig={{
											url: isPublicView
												? `api/public/url/carriers/${carrierId}/files`
												: `api/booking/carriers/${carrierId}/files`,
											documentType: "trip",
											fileName: "file_id",
											type: "file_id",
											params: {
												...(isPublicView ? { options_public_user_context: userId } : {}),
											},
										}}
										url={
											isPublicView
												? `api/public/url/booking/carrier/${carrierId}/trips/${tripId}/files/type/pod_files`
												: `api/booking/carrier/${carrierId}/trips/${tripId}/files/type/pod_files`
										}
										userId={userId}
										carrierId={carrierId}
										isPublicView={isPublicView}
										isEnabledFilesAnalysis={isEnabledFilesAnalysis}
										selectedLink={selectedLink}
										setSelectedLink={setSelectedLink}
										setFileLoading={setFileLoading}
										setLoading={setLoading}
										loading={loading}
										fileInputRef={fileInputRef}
										native={isNative}
										errorFiles={errorFiles}
									/>
								</div>
								{!nativeMobile &&
									!processTierRestrictions({ restrictions: ["TIER_PROFESSIONAL"], tier: userTier }) &&
									!isPublicView && (
										<div className="mb-5">
											<Alert severity="info">
												Want to process files automatically?
												<Link className="cursor-pointer px-4" onClick={() => openPaywallView()}>
													<span className="text-primary font-semibold underline">Click here</span>
												</Link>
												to learn how you could be saving time with automatic file checks.
											</Alert>
										</div>
									)}
							</div>
						),
					},
				]}
				footerActions={[
					{
						key: "SCANNER",
						title: "Scan Documents",
						style: {
							align: "right",
							notCollapsible: true,
							icon: "scanner",
							primary: true,
						},
						hideOnDesktop: true,
						hideOnMobile: !isNative,
						onClick: () => {
							window?.ReactNativeWebView?.postMessage(
								JSON.stringify({ type: "DOCUMENT_SCAN", data: { fileName: fileNameId } })
							);
						},
					},
					{
						key: "UPLOAD",
						title: "Upload Images",
						style: {
							align: "RIGHT",
							notCollapsible: true,
							icon: "note_add",
							primary: true,
						},
						hideOnDesktop: true,
						hideOnMobile: !isNative,
						onClick: () => {
							fileInputRef?.current?.click();
						},
					},
				]}
			></SmarthopDialogViewContainer>
		</>
	);
});

export default TripFilesForm;
