// Services
import { getAccountId } from "app/services/LoginService";

// Tools
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { fetchSubAccount } from "app/store/auth/accountSlice";
import { closeAllDialogs } from "app/store/tools/formDialogSlice";

const carrierDispatchingDisconnectForm = (from) => {
	const isFromCarrier = from === "CARRIER";
	return {
		urlGET: "api/profile/carriers/:carrierId/dispatching/details/:dispatchingType",
		urlDELETE: "api/profile/carriers/:carrierId/dispatching/disconnect",
		idKey: "_id",
		header: {
			delete: { label: `Disconnect ` + (isFromCarrier ? "Dispatcher" : "Carrier") },
		},
		content: {
			delete: {
				items: [
					{
						type: "message",
						builder: (item) => {
							if (item.company || item.carrier) {
								return (
									`Would you like to disconnect from the ` +
									(isFromCarrier ? `dispatcher '${item.company}'?` : `carrier '${item.carrier}'?`)
								);
							} else {
								return "Currently do not have any dispatchers connected";
							}
						},
					},
					{
						type: "group",
						content: {
							items: [
								{
									type: "action",
									action: "CANCEL",
									label: "Close",
									hidden: (item) => item.company,
									button: { alwaysEnabled: true },
								},
								{ type: "action", action: "CANCEL", hidden: (item) => !item.company },
								{ type: "action", action: "DELETE", label: "Disconnect", hidden: (item) => !item.company },
							],
						},
					},
				],
				onFinished: (_, dispatch) => {
					dispatch(fetchSubAccount({ accountId: getAccountId() }));
					dispatch(incrementDataRevision({ event: "accountRevision" }));
					dispatch(incrementDataRevision({ event: "carrierDispatchingRevision" }));
					dispatch(closeAllDialogs());
				},
			},
		},
	};
};

export default carrierDispatchingDisconnectForm;
