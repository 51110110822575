import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { Tooltip } from "@material-ui/core";

// Utils
import { formatDate } from "app/main/utils/dateUtils";
import { renderDataItem } from "app/main/utils/uiUtils";
import { createTooltip } from "app/main/utils/tableUtils";

const StyledTimelineItem = withStyles({
	missingOppositeContent: {
		"&:before": {
			display: "none",
		},
	},
})(TimelineItem);

function formatText(value) {
	return value && value !== "" ? value : "-";
}

function ucwords(str) {
	return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

function TripStopPanel(props) {
	const load = props.load;
	const dataTimeLine = [];

	const getLocationType = (locationType = "STOP", addLocation = false, addTime = false) => {
		const LOCATION = "Location",
			TIME = "Time";
		if (locationType === "STOP") {
			addLocation = false;
			addTime = false;
		}
		locationType = addLocation
			? `${ucwords(locationType)} ${LOCATION}`
			: addTime
			? `${ucwords(locationType)} ${TIME}`
			: ucwords(locationType);
		return locationType;
	};

	const tripOverlapWarning = load?.overlapWarning;
	if (load.all_locations?.length) {
		let locations = load.all_locations;
		let count = 0;
		for (let location of locations) {
			// Checking if current stop is overlapping with another trip (another stop in a different trip)
			const isOverlapping = tripOverlapWarning?.affectedCurrentStops?.includes(count);
			let overlappingWarning;
			if (isOverlapping) {
				overlappingWarning = tripOverlapWarning.description;
			}

			dataTimeLine.push([
				[
					{
						value: location.location?.toUpperCase?.() ?? "-",
						label:
							getLocationType(location.type, true) +
							(location.zip_code_location ? " | Zip " + location.zip_code_location : ""),
						tooltip: location.complete ? { icon: "check", message: "Stop Completed", color: "text-green" } : undefined,
						isOverlapping,
						overlappingWarning,
					},
				],
				{
					value: location.location_date ? formatDate(location.location_date, location.location_window_date) : "-",
					label: props?.condensed ? "" : getLocationType(location.type, false, true),
					variant: "row",
				},
				...(location?.type !== "EMPTY" && !props?.condensed
					? [
							{
								value: formatText(location.location_shipper),
								label: "Shipper",
								variant: "row",
							},
							...(location.location_number && location.location_number?.toUpperCase() !== "NONE"
								? [
										{
											value: formatText(location.location_number),
											label: `${
												location?.type === "PICKUP" ? "Pickup " : location?.type === "DELIVERY" ? "Delivery " : ""
											}Number`,
											variant: "row",
										},
								  ]
								: []),
							...((location.location_contact && location.location_contact?.toUpperCase() !== "NONE") ||
							// Showing empty contact item to indicate that no contact has been provided, phone or contact is nessesary
							location.location_contact
								? [
										{
											value: formatText(location.location_contact),
											label: "Contact",
											variant: "row",
										},
								  ]
								: []),
							...(location.location_phone && location.location_phone?.toUpperCase() !== "NONE"
								? [
										{
											value: formatText(location.location_phone),
											label: "Phone",
											variant: "row",
										},
								  ]
								: []),
					  ]
					: []),
			]);
			count++;
		}
	}

	const renderInfo = (info, columns) => {
		if (!info.label && props?.condensed) {
			return <div>{info.value}</div>;
		}
		if (info.variant === "row") {
			return <div className="w-full">{renderDataItem(info.label, info.value)}</div>;
		} else {
			return (
				<div className={columns > 1 ? "w-2/4" : "w-full"}>
					<div className="flex flex-row items-center">
						<Typography className="text-12 ml:text-13 tracking-wide">{info.label}</Typography>
						{info.tooltip &&
							createTooltip(
								<Icon className={`text-15 -mb-3 ml-3 ${info.tooltip.color}`}>{info.tooltip.icon ?? "info"}</Icon>,
								<Typography className="break-words whitespace-normal min-w-100 text-12 font-light">
									{info.tooltip.message}
								</Typography>,
								"bg-gray-600"
							)}
						<Icon className="text-green text-15 ml:text-16 ml-3">{info.icon}</Icon>
					</div>
					<Typography className="text-12 ml:text-13 break-all -mt-2 font-semibold">{info.value}</Typography>
				</div>
			);
		}
	};

	const firstEmpty = dataTimeLine?.[0]?.[0]?.[0]?.label?.toUpperCase()?.includes("EMPTY");
	return (
		<div className={props?.classes?.root}>
			<Timeline className="-ml-14 mt-4 -mb-6 -mr-10">
				{dataTimeLine.map((data, index) => (
					<StyledTimelineItem key={index}>
						<TimelineSeparator>
							<TimelineDot
								color={firstEmpty && index === 0 ? "primary" : "secondary"}
								className={"flex items-center justify-center h-28 w-28 border-1 shadow-none "}
							>
								<div className="flex items-center justify-center">
									<Typography className="font-semibold text-12">{index + 1}</Typography>
								</div>
							</TimelineDot>
							{dataTimeLine.length !== index + 1 && <TimelineConnector />}
						</TimelineSeparator>
						<TimelineContent className="pr-6 pb-16">
							{data.map((elem, index2) =>
								Array.isArray(elem) ? (
									<div key={index2} className="flex space-x-10">
										<div className="flex-row flex w-full">
											{elem[0] && renderInfo(elem[0], elem[1] ? 2 : 1)}
											{elem[0]?.isOverlapping && (
												<div className="flex flex-col">
													<Tooltip
														title={elem[0]?.overlappingWarning}
														placement="right"
														arrow
														classes={{ tooltip: "text-11" }}
													>
														<Typography className="cursor-default w-fit py-2 text-white bg-red px-6 rounded-lg flex flex-row items-center space-x-4">
															<Icon className="text-11">warning</Icon>
															<Typography className="text-11">Overlapping</Typography>
														</Typography>
													</Tooltip>
												</div>
											)}
										</div>
										{elem[1] && renderInfo(elem[1], elem[1] ? 2 : 1)}
									</div>
								) : (
									<div key={index2} className="flex space-x-10">
										{renderInfo(elem, 1)}
									</div>
								)
							)}
						</TimelineContent>
					</StyledTimelineItem>
				))}
			</Timeline>
		</div>
	);
}

export default TripStopPanel;
