/* eslint-disable react-hooks/rules-of-hooks */
// Dependencies
import { useCallback, useMemo, useState, useEffect } from "react";
import { Button, FormControlLabel, Icon, IconButton, Link, Radio, Switch, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import uniqid from "uniqid";

// Components
import TripLogisticsCostsComponent from "../TripLogisticsCostsComponent";
import { SmarthopConfirmDialog, SmarthopForm } from "@smarthop/form";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import PlaidLink from "app/main/billing/payments/plaid/PlaidLink.js";

// Services
import * as fileService from "app/services/fileServices";
import { getRoleLabel, isInternalPermission, isCarrier } from "app/services/LoginService";
import { updateInvoicePDFSettings, updateInvoiceStatus } from "app/services/tripInvoicingServices";
import { getPlaidLinkToken } from "app/services/billingServices";

import PayoutCellView from "@smarthop/list/views/PayoutCellView";

// Utils and tools
import { DisplaySection, renderDataItem } from "app/main/utils/uiUtils";
import { tripHelpers } from "app/main/profile/trips/tools/helpers";
import { formatCurrency, createTooltip } from "app/main/utils/tableUtils";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { useDispatch, useSelector } from "react-redux";
import { openFormDialog, openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { fetchActions } from "app/store/actions/actionsUserSlice";
import { smartpayInvoiceErrorsFormatter } from "./invoiceUtils";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { hasRequiredGateKeepers } from "app/main/utils/rolesUtils";
import { formatDate } from "app/main/utils/dateUtils";

// Consts
import {
	COLLECTION_STATUSES_LABELS,
	INVOICE_COLLECTION_STATUSES_OPTIONS,
	INVOICE_STATUSES_OPTIONS,
	INVOICE_STATUSES_LABELS,
	INVOICE_STATUSES_SP_LABELS_ADMIN,
	getStatusOptions,
} from "app/main/consts/tripInvoiceConsts";
import SmartPayJournalEntriesComponent from "../SmartPayJournalEntriesComponent";
import { uploadFiles } from "app/main/multi-file-upload/MultiFileUploadView";
import { useRef } from "react";
import { getTrip } from "app/services/tripsServices";

const FILES_TYPES_NAMES = {
	invoice: "Invoice",
	pod: "Bill Of Lading",
	rate_confirmation: "Rate Confirmation",
	proof_of_delivery: "Proof Of Delivery",
	packing_list: "Packing List",
	lumper_receipt: "Lumper Receipt",
	"dock/warehouse_receipt": "Dock/Warehouse Receipt",
	scale_or_weight_receipt: "Scale or Weight Receipt",
	cargo_picture: "Cargo Picture",
	display_screen: "Display Screen",
	rateConf: "Rate Confirmation",
	notAvailable: "Document not available.",
};

const PRIORITY_TYPE = {
	pod: 8,
	rate_confirmation: 7,
	proof_of_delivery: 6,
	packing_list: 5,
	lumper_receipt: 4,
	"dock/warehouse_receipt": 3,
	scale_or_weight_receipt: 2,
	cargo_picture: 1,
	display_screen: 0,
	other: -1,
};

const useStyles = makeStyles((theme) => {
	return {
		message: {
			width: "100%",
		},
	};
});

const DocumentDetail = ({ item, icon, title, hasFile }) => {
	const dispatch = useDispatch();

	if (hasFile) {
		return createTooltip(icon, title);
	} else {
		return (
			<Link
				className="flex whitespace-nowrap "
				onClick={() =>
					dispatch(
						openLoadedFormDialog({
							viewId: "UPLOAD_FILES_FORM",
							mode: "EDIT",
							dataIds: {
								carrierId: item?.carrier,
								tripId: item?.trip_data?.trip,
							},
						})
					)
				}
			>
				<div className="flex flex-row items-center">
					Add File <Icon className="text-20">attach_file</Icon>
				</div>
			</Link>
		);
	}
};

const TripInvoicingDetails = (props) => {
	const isView = props?.isView;
	const tripInvoice = props.tripInvoice;
	const selectedLink = props.selectedLink;
	const setSelectedLink = props.setSelectedLink;
	const setFileLoading = props.setFileLoading;
	const readOnly = props.readOnly;
	const carrierId = tripInvoice.carrier;
	const invoicePDFURI = props.invoicePDFURI;
	const tripData = tripInvoice?.trip_data;
	const nativeMobile = props?.nativeMobile;
	const classes = useStyles();

	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const role = useMemo(() => getRoleLabel(), []);

	// Flags
	const isAdmin = role === "administrator";
	const isInternal = useMemo(() => isInternalPermission(role), [role]);
	const isCarrierSmartpayEnabled = tripInvoice?.carrier_data?.hasSmartPayProgram;
	const isInvoiceSmartPayEligible = tripInvoice?.smartpay_eligible && isCarrierSmartpayEnabled;
	const isInvoiceSmartpayFlow = isInvoiceSmartPayEligible && tripInvoice?.status?.includes("SP_");
	const isStatusEditable = isInvoiceSmartpayFlow && (!isInvoiceSmartPayEligible || isAdmin);

	// Selectors
	const user = useSelector(({ auth }) => auth.user);

	// Consts
	const amountToFund = tripInvoice?.smartpay?.amount_to_fund;
	const showCollectionStatus = isAdmin && tripInvoice.status === "SP_FUNDED";
	const isRejected = tripInvoice.status === "SP_REJECTED";
	const hasChangesRequested = tripInvoice.status === "SP_CHANGE_REQUESTED";
	const showSmartPayReason = isRejected || hasChangesRequested;
	const smartpayMetadata = tripInvoice?.smartpay_metadata;
	const invoiceStatusSpOptions = isInvoiceSmartPayEligible ? getStatusOptions(tripInvoice.status) : [];
	const invoiceHasAccessEdit = hasRequiredGateKeepers(user, { permission_invoice_access: "editor" });
	const lastSent = tripInvoice.smartpay_last_sent_to_broker;
	const smartpayReason = isRejected
		? tripInvoice?.smartpay_metadata?.rejected_reason
		: hasChangesRequested
		? tripInvoice?.smartpay_metadata?.reason
		: "";

	// States
	const [plaidToken, setPlaidToken] = useState(null);
	const [sourceId, setSourceId] = useState(null);
	const [isSmartpayPayoutAccount, setIsSmartpayPayoutAccount] = useState(false);
	const [reason, setReason] = useState();
	const [confirmationModel, setConfirmationModel] = useState();
	const [showExternalFactoringSection, setShowExternalFactoringSection] = useState(
		tripInvoice?.show_external_factoring_section ??
			(tripInvoice?.carrier_data?.external_factoring && tripInvoice.status === "PENDING")
	);

	const inputFileRef = useRef();

	// Hack to re-render the form when the state of the tripInvoice.status changes
	const [formKey, setFormKey] = useState(Math.random());
	useEffect(() => {
		setFormKey(Math.random());
	}, [tripInvoice.status]);
	const [reasonFormErrors, setReasonFormErrors] = useState([]);

	const logisticCost = useMemo(
		() => tripHelpers.mapLogisticsCosts(tripData?.additions_deductions || [], { ignoreDescriptions: true }),
		[tripData]
	);

	// Add ACH Methods/Actions
	const handleAddAch = async ({ accessToken, isSmartpayPayoutAccountHandler }) => {
		const data = { accessToken };
		const linkToken = await getPlaidLinkToken(carrierId, data);
		if (!linkToken) {
			showSnackbar(snackbar, "There was an error creating token plaid", "error", 5000);
		}
		if (isSmartpayPayoutAccountHandler) setIsSmartpayPayoutAccount(isSmartpayPayoutAccountHandler);
		if (sourceId) setSourceId(sourceId);
		setPlaidToken(linkToken);
	};

	const onSetSelectedLink = (newLink) => {
		setFileLoading?.(true);
		setSelectedLink?.(newLink);
		setTimeout(() => {
			setFileLoading?.(false);
		}, 1000);
	};

	const onclickFileLink = (e, link, options = {}) => {
		e.preventDefault();
		if (nativeMobile)
			return window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "FILE_PREVIEW", url: link }));
		dispatch(
			openFormDialog({
				viewId: "FILE_VISUALIZER_VIEW",
				dataIds: {
					urlFilePreview: link,
					errorMessage:
						options.invoiceFile && !tripInvoice.invoice_file__flag
							? "To preview the invoice it must have been generated"
							: options.rateConfFile && !tripData?.rate_con_file__flag
							? "Rate confirmation not available"
							: options.pod_file && !tripData?.pod_file__flag
							? "Bill of lading not available"
							: null,
				},
			})
		);
	};

	const onFileUploadGPT = async (e) => {
		const uploadId = uniqid();
		const trip = await getTrip(tripInvoice?.trip_data?.trip, carrierId);
		const response = await uploadFiles(Array.from(e.target.files), dispatch, uploadId, true, carrierId, true);
		const { fileId } = response;
		const rcDataEditable = { ...trip };
		rcDataEditable.rate_con_file = [fileId];
		rcDataEditable.rate_con_file__flag = true;
		dispatch(
			openLoadedFormDialog({
				viewId: "TRIP_EDIT_VIEW",
				dataIds: { tripId: trip._id, carrierId, rcDataEditable, uploadId },
			})
		);
	};

	const onEdit = useCallback(async () => {
		dispatch(
			openLoadedFormDialog({
				viewId: "TRIP_INVOICE_VIEW",
				dataIds: props?.dataIds,
			})
		);
	}, [dispatch, props?.dataIds]);

	if (!tripInvoice) {
		return null;
	}

	const handleReasonChange = ({ reason }) => {
		setReason(reason);
	};

	const handleInvoiceStatusChange = (model) => {
		let message, acceptMsg;
		if (model.status !== tripInvoice.status) {
			message =
				INVOICE_STATUSES_SP_LABELS_ADMIN[model.status] &&
				`Do you want to change invoice status to ${INVOICE_STATUSES_SP_LABELS_ADMIN[model.status]}?`;
		} else if (model.collection_status !== tripInvoice.collection_status) {
			message =
				COLLECTION_STATUSES_LABELS[model.collection_status] &&
				`Do you want to change invoice collection status to ${COLLECTION_STATUSES_LABELS[model.collection_status]}?`;
		}
		setConfirmationModel({
			message: message ?? "Are you sure you want to perform this action?",
			acceptMsg: acceptMsg ?? "Continue",
			showReasonForm: ["SP_CHANGE_REQUESTED", "SP_REJECTED"].includes(model.status),
			onCancel: () => {
				// Hack to reset the dropdown to invoice values
				setFormKey(Math.random());
			},
			onAccept: async (reason) => {
				try {
					await updateInvoiceStatus(carrierId, tripInvoice?._id, model.status, { ...model, reason });
					dispatch(incrementDataRevision({ event: "tripsRevision" }));
					dispatch(incrementDataRevision({ event: "invoiceRevision" }));
					if (isCarrier()) {
						dispatch(fetchActions({ carrierId: tripInvoice?.carrier }));
					}
					if (nativeMobile) {
						window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_TRIP_LIST", data: { carrierId } }));
					}
				} catch (e) {
					// If update failed, show error message
					showSnackbar(snackbar, e.errors?.[0]?.message ?? "Opps...", "error");
				}
			},
		});
	};

	/**
	 * Handles Switch changes to show or hide Factoring information in the invoice
	 * @param {*} value Switch value
	 */
	const handleShowFactoringInInvoice = async (value) => {
		const updateAction = async () => {
			setShowExternalFactoringSection(value);
			await updateInvoicePDFSettings(carrierId, tripData.trip, {
				show_external_factoring_section: value,
			});
			dispatch(incrementDataRevision({ event: "tripsRevision" }));
			dispatch(incrementDataRevision({ event: "invoiceRevision" }));
		};

		if (tripInvoice.status !== "PENDING") {
			// Show confirmation
			setConfirmationModel({
				message: "This action will reset your Invoice to Pending status",
				acceptMsg: "Continue",
				onAccept: async (reason) => {
					await updateAction();
				},
			});
		} else {
			await updateAction();
		}
	};

	const hasSmartpayAccount = tripInvoice.carrier_data?.smartpay?.hasSmartpayAccount;
	const smartpayAccountRequiresReauth = tripInvoice.carrier_data?.smartpay?.reAuthRequired;
	const smartpayInvoiceErrors = smartpayInvoiceErrorsFormatter(tripInvoice);
	const smartpayInvoiceErrorsArray = Object.keys(smartpayInvoiceErrors);

	const eligibleAmountCents = tripInvoice?.smartpay?.factorable_amount_cents;
	const smartpayFeePercentage = tripInvoice?.smartpay?.smartpay_fee_percentage;
	const smartpayFeeCents = tripInvoice?.smartpay?.smartpay_fee_cents;

	const smartpayPaymentType = {
		ACH_PAYMENT: "ACH",
		WIRE_PAYMENT: "Same Day",
	}[tripInvoice.smartpay?.nextPayout?.payment_type];
	const transferFee = tripInvoice?.smartpay?.nextPayout?.transfer_fee_cents / 100;

	let totalNextPayoutCents = tripInvoice?.smartpay?.nextPayout?.net_amount_cents ?? 0;
	// TODO | This can be removed when all invoices have a net_amount_cents
	if (!totalNextPayoutCents) {
		tripInvoice?.smartpay?.nextPayout?.entries?.forEach(
			(item) => (totalNextPayoutCents = totalNextPayoutCents + item.amount_cents)
		);
		totalNextPayoutCents = totalNextPayoutCents - tripInvoice?.smartpay?.nextPayout?.transfer_fee_cents;
	}

	const nextPayoutHasRelatedEntries = tripInvoice?.smartpay?.nextPayout?.entries?.find(
		(entry) => entry.links?.invoice === tripInvoice?._id
	);

	const nextPayoutIsOpen = tripInvoice?.smartpay?.nextPayout?.status === "OPEN";

	const isShowSmartPayFigures = !["PENDING"].includes(tripInvoice?.status);

	const journalEntries = tripInvoice?.journalEntries;

	return (
		<>
			{["PENDING", "GENERATED"].includes(tripInvoice?.status) &&
				isInvoiceSmartPayEligible &&
				smartpayInvoiceErrorsArray?.length > 0 && (
					<Alert
						severity={"error"}
						className="mb-14 w-full mx-0"
						classes={{
							message: classes.message,
						}}
					>
						<Typography>One or more issues must be addressed before submitting your Invoice:</Typography>
						{Object.keys(smartpayInvoiceErrors)?.map((key) => (
							<Typography>{smartpayInvoiceErrors[key]}</Typography>
						))}
						{!isInternal && (!hasSmartpayAccount || smartpayAccountRequiresReauth) && (
							<div className="flex  mt-8 justify-end">
								<Button
									variant="contained"
									color="secondary"
									onClick={() => handleAddAch({ isSmartpayPayoutAccountHandler: true })}
								>
									{smartpayAccountRequiresReauth ? "Re-authenticate Payout Account" : "Add Payout Account"}
								</Button>
							</div>
						)}
					</Alert>
				)}
			{showSmartPayReason && (
				<Alert severity={isRejected ? "error" : hasChangesRequested ? "warning" : "info"} className="mb-14">
					<Typography>
						{isRejected
							? "Your SmartPay invoice was rejected. Acknowledge to factor manually"
							: hasChangesRequested
							? "Your SmartPay invoice requires changes"
							: ""}
					</Typography>
					<Typography>
						<b> Reason: </b> {smartpayReason ?? "No reason provided"}
					</Typography>
				</Alert>
			)}
			<div className="flex flex-col md:flex-row md:space-x-16">
				<div className="flex w-full flex-col md:flex-row">
					<DisplaySection
						classes={{ root: "md:w-full" }}
						title={
							<div className="w-full flex flex-col">
								{!isView && (
									<>
										{!isInvoiceSmartPayEligible && (
											<div className="flex w-full flex-col -mt-2 pb-12">
												<SmarthopForm
													key={formKey}
													form={{
														// We'll send the status update in the body of the request instead
														urlPUT: "api/trip-invoicing/carriers/:carrierId/invoices/:invoiceId/status",
														listenEvent: ["tripsRevision", "invoiceRevision"],
														triggerEvent: "tripsRevision",
														idKey: "_id",
														messages: { edit: { success: "Saved" } },
														content: {
															edit: {
																submitOnChange: true,
																noInitValidation: true,
																form: { noErrorMessage: true },
																items: [
																	{
																		key: "status",
																		type: "select",
																		label: "Status",
																		options: INVOICE_STATUSES_OPTIONS,
																		field: {
																			classes: { root: "min-w-180 -ml-5" },
																			noErrorMessage: true,
																		},
																		select: { classes: { input: "min-w-180 -ml-5" } },
																	},
																],
																onError: (errors, _, snackbar) => {
																	showSnackbar(snackbar, errors?.[0].message ?? "Opps...", "error");
																},
																onFinished: () => {
																	if (isCarrier()) {
																		dispatch(fetchActions({ carrierId: tripInvoice?.carrier }));
																	}
																	if (nativeMobile) {
																		window?.ReactNativeWebView?.postMessage(
																			JSON.stringify({ type: "REFRESH_TRIP_LIST", data: { carrierId } })
																		);
																	}
																},
															},
														},
													}}
													dataIds={{ carrierId: tripInvoice?.carrier, invoiceId: tripInvoice?._id }}
													data={{ status: tripInvoice.status }}
													mode={invoiceHasAccessEdit ? "EDIT" : "VIEW"}
												/>
											</div>
										)}
										{isInvoiceSmartPayEligible && isAdmin && (
											<SmarthopFormView
												key={`${formKey}_SP`}
												content={{
													items: [
														{
															key: "status",
															type: "select",
															label: "Status",
															options: invoiceStatusSpOptions,
															field: {
																classes: { root: "min-w-180 -ml-5" },
																noErrorMessage: true,
															},
															disabled: !isStatusEditable,
															select: { classes: { input: "min-w-180 -ml-5" } },
														},
														...(showCollectionStatus
															? [
																	{
																		key: "collection_status",
																		type: "select",
																		label: "Collection status",
																		options: INVOICE_COLLECTION_STATUSES_OPTIONS,
																		field: {
																			classes: { root: "min-w-180 -ml-5" },
																			noErrorMessage: true,
																		},
																		select: { classes: { input: "min-w-180 -ml-5" } },
																	},
															  ]
															: []),
													],
												}}
												dataIds={{ carrierId: tripInvoice?.carrier, tripId: tripInvoice?.trip_data?.trip }}
												data={{ status: tripInvoice.status, collection_status: tripInvoice.collection_status }}
												onChangeCommitted={handleInvoiceStatusChange}
												trackChangedFields={["ALL"]}
												mode={invoiceHasAccessEdit ? "EDIT" : "VIEW"}
											/>
										)}
									</>
								)}
								<div className="w-full flex flex-row content-center justify-between items-center space-x-16">
									<div>Details</div>
									<div className="space-x-8 float-right -mt-8 -mb-20">
										{isView && invoiceHasAccessEdit && isInvoiceSmartpayFlow && (
											<IconButton
												aria-label="edit"
												className="-mt-20"
												size="medium"
												color="inherit"
												onClick={() => onEdit()}
												title="Trip invoice details"
											>
												<Icon className={"text-20 text-black font-500"}>edit</Icon>
											</IconButton>
										)}
									</div>
								</div>
							</div>
						}
					>
						<div className="-mt-10">
							{renderDataItem(
								"Invoice",
								tripInvoice.carrierRefLabel
									? tripInvoice.carrierRefLabel
									: tripInvoice.carrier_ref
									? "#" + tripInvoice._carrier_ref
									: tripInvoice._id,
								"",
								{}
							)}
							{renderDataItem(
								"Status",
								INVOICE_STATUSES_LABELS[tripInvoice.status] ??
									INVOICE_STATUSES_SP_LABELS_ADMIN[tripInvoice.status] ??
									tripInvoice.status
							)}
							{renderDataItem("Load", tripInvoice.trip_data?.load_id, "", {})}
							{renderDataItem("Broker Name", tripInvoice.broker_data?.broker_name, "", {})}
							{tripInvoice?.generated_date &&
								renderDataItem("Invoice Date", formatDate(tripInvoice.generated_date), "", {})}
							{tripInvoice?.due_date && renderDataItem("Due Date", formatDate(tripInvoice.due_date), "", {})}
							{renderDataItem("Invoice Total", formatCurrency(tripInvoice.amount), "", {})}
						</div>
					</DisplaySection>
				</div>
			</div>

			{isInvoiceSmartPayEligible && (
				<>
					<div className="flex flex-col md:flex-row md:space-x-16">
						<div className="flex w-full flex-col md:flex-row">
							<DisplaySection classes={{ root: "md:w-full" }} title={"SmartPay"}>
								<div className="-mt-10">
									{isShowSmartPayFigures &&
										renderDataItem(`Eligible Amount `, `${formatCurrency(eligibleAmountCents / 100)}`, "", {
											classes: { root: "text-right" },
											tooltip: {
												label: {
													message:
														"Some invoice items like additions and deduction might not be eligible for SmartPay and will" +
														" be paid to your account only when full invoice amount is collected from the broker",
												},
											},
										})}
									{isShowSmartPayFigures &&
										renderDataItem(
											`SmartPay Fee | ${smartpayFeePercentage}% `,
											`${formatCurrency(smartpayFeeCents / 100)}`,
											"",
											{ classes: { root: "text-right" } }
										)}
									{renderDataItem(
										"Fund Amount ",
										!["PENDING"].includes(tripInvoice?.status) && amountToFund
											? formatCurrency(amountToFund)
											: "Generate invoice to see amount",
										"",
										{}
									)}
									{tripInvoice?.submitted_date &&
										renderDataItem("Submission Date ", formatDate(tripInvoice?.submitted_date), "", {})}
								</div>
							</DisplaySection>
						</div>
					</div>

					{!!journalEntries?.length && (
						<div className="flex flex-col md:flex-row md:space-x-16 ml-40">
							<SmartPayJournalEntriesComponent
								title="Line Items"
								payoutEntries={journalEntries}
								hideDescription={true}
								hideInvoice={true}
							/>
						</div>
					)}

					{nextPayoutHasRelatedEntries && nextPayoutIsOpen && (
						<div className="flex flex-col md:flex-row md:space-x-16 bg-blue-50 border-1 border-blue-400 px-12 py-10 rounded-lg mb-10 mx-10">
							<div className="flex w-full flex-col md:flex-row">
								<DisplaySection
									classes={{ root: "md:w-full", divider: "bg-blue-200" }}
									title={"Next Payout"}
									views={[
										<div className="flex flex-row font-normal -mb-3">
											<PayoutCellView
												variant={"large"}
												item={tripInvoice?.smartpay?.nextPayout}
												view={tripInvoice?.smartpay?.nextPayout?.entries?.[0]?.payout__view}
											/>
										</div>,
									]}
								>
									<div className="-mt-10">
										{renderDataItem(`Transfer Fee | ${smartpayPaymentType}`, `${formatCurrency(transferFee)}`, "", {
											classes: { root: "text-right border-b-1 border-blue-200" },
										})}
										{renderDataItem(`Net Amount *`, `${formatCurrency(totalNextPayoutCents / 100)}`, "", {
											classes: { root: "text-right border-b-1 border-blue-200" },
										})}
									</div>

									<Typography className="text-11 text-grey-600 mt-12 -mb-10">
										* Some of the items in this invoice will be included in the next 'Payout' and will be transferred to
										your account as soon as all included invoices are reviewed and approved
									</Typography>
								</DisplaySection>
							</div>
						</div>
					)}
				</>
			)}

			{/* Visible to INTERNAL users only */}
			{(isAdmin || isInternal) && (
				<div className="flex flex-col md:flex-row md:space-x-16">
					<div className="flex w-full flex-col md:flex-row">
						<DisplaySection classes={{ root: "md:w-full" }} title={"Internal"}>
							<div className="-mt-10">
								{isInternal &&
									smartpayMetadata &&
									renderDataItem("SmartPay Metadata", smartpayMetadata.reason, "", { classes: { root: "text-right" } })}
								{lastSent?.date &&
									renderDataItem("Last sent to Broker", `${formatDate(lastSent.date)} | to ${lastSent.to}`, "", {})}
							</div>
						</DisplaySection>
					</div>
				</div>
			)}

			<div className="flex flex-col md:flex-row md:space-x-16">
				<div className="flex w-full flex-col">
					{!tripInvoice?.carrier_data?.hasSmartPayProgram && (
						<DisplaySection classes={{ root: "md:w-full" }} title={"Settings"}>
							<div className="-mt-10">
								{renderDataItem(
									"Show Payment Information",
									<FormControlLabel
										classes={{ root: "-mt-8 p-0" }}
										control={
											<Switch
												checked={showExternalFactoringSection}
												onChange={(event) => handleShowFactoringInInvoice(event.target.checked)}
											/>
										}
										label=""
									/>,
									"",
									{
										classes: { root: "text-right", value: "mt-0" },
										tooltip: {
											label: {
												message: "To add Payment Information go to your Profile Details, Invoicing Module",
											},
										},
									}
								)}
							</div>
						</DisplaySection>
					)}
					<TripLogisticsCostsComponent
						logisticsCosts={logisticCost}
						title={"Additions and Deductions"}
						styleClasses={{ root: "md:w-full" }}
					/>
				</div>

				<div className="flex w-full flex-col md:flex-row">
					<DisplaySection
						classes={{ root: "md:w-full" }}
						title={
							<div className="w-full flex flex-row content-center justify-between items-center space-x-16">
								<div>Documents</div>
							</div>
						}
					>
						<div
							key={tripInvoice._id}
							className="flex flex-row border-b-1 border-grey-300 -mt-8 items-center content-center w-full justify-between"
						>
							<div className="flex flex-row space-between w-full items-center content-center">
								{tripInvoice.invoice_file__flag && !readOnly && !nativeMobile && (
									<Radio
										className="pr-4 pl-0"
										checked={selectedLink?.id === tripInvoice._id}
										onChange={() => onSetSelectedLink({ id: tripInvoice._id, link: invoicePDFURI })}
										value={tripInvoice._id}
									/>
								)}
								{!tripInvoice.invoice_file__flag &&
									createTooltip(
										<Icon className="text-22 text-red my-2">highlight_off</Icon>,
										FILES_TYPES_NAMES["notAvailable"],
										"",
										"pr-4 pl-0 mt-4"
									)}
								{!tripInvoice.invoice_file__flag ? (
									<Typography className="text-12">{`${FILES_TYPES_NAMES["invoice"]}`}</Typography>
								) : (
									<Link
										className="text-12 ml:text-13 break-normal py-4"
										key={tripInvoice._id}
										onClick={(e) => onclickFileLink(e, invoicePDFURI, { invoiceFile: true })}
									>
										{`${FILES_TYPES_NAMES["invoice"]} `}
									</Link>
								)}
							</div>
							{createTooltip(<Icon className="text-20 text-orange my-4">receipt</Icon>, FILES_TYPES_NAMES["invoice"])}
						</div>
						{
							<div
								key={tripData?.rate_con_file?.[0]}
								className="flex flex-row border-b-1 border-grey-300 items-center content-center w-full justify-between"
							>
								<div className="flex flex-row space-between w-full items-center content-center">
									{tripData?.rate_con_file__flag && tripData?.rate_con_file && !readOnly && !nativeMobile && (
										<Radio
											className="pr-4 pl-0"
											checked={selectedLink?.id === tripData?.rate_con_file?.[0]}
											onChange={() =>
												onSetSelectedLink({
													id: tripData?.rate_con_file?.[0],
													link: fileService.buildLink(tripData?.rate_con_file?.[0]),
												})
											}
											value={tripData?.rate_con_file?.[0]}
										/>
									)}
									{!tripData?.rate_con_file__flag &&
										createTooltip(
											<Icon className="text-22 text-red my-4">highlight_off</Icon>,
											FILES_TYPES_NAMES["notAvailable"],
											"",
											"pr-4 pl-0 mt-4"
										)}

									{!tripData?.rate_con_file?.[0] ? (
										<Typography className="text-12"> {`${FILES_TYPES_NAMES["rateConf"]} `}</Typography>
									) : (
										<Link
											className="text-12 ml:text-13 break-normal py-4"
											key={tripData?.rate_con_file?.[0]}
											onClick={(e) => onclickFileLink(e, fileService.buildLink(tripData?.rate_con_file?.[0]), {})}
										>
											{`${FILES_TYPES_NAMES["rateConf"]} `}
										</Link>
									)}
								</div>
								{tripData?.rate_con_file__flag &&
									createTooltip(
										<Icon className="text-20 text-green my-4">request_quote</Icon>,
										FILES_TYPES_NAMES["rateConf"]
									)}
								{!tripData?.rate_con_file__flag && (
									<>
										<input className="hidden" type="file" ref={inputFileRef} onChange={onFileUploadGPT} />
										<Link className="flex whitespace-nowrap" onClick={() => inputFileRef?.current?.click()}>
											<div className="flex flex-row items-center">
												Add File <Icon className="text-20">attach_file</Icon>
											</div>
										</Link>
									</>
								)}
							</div>
						}
						{!tripData?.pod_file__flag && (
							<div
								key={"not_available_pod_file"}
								className="flex flex-row border-b-1 border-grey-300 items-center content-center w-full justify-between"
							>
								<div className="flex flex-row space-between w-full items-center content-center">
									{!tripData?.pod_file__flag &&
										createTooltip(
											<Icon className="text-22 text-red my-2">highlight_off</Icon>,
											FILES_TYPES_NAMES["notAvailable"],
											"",
											"pr-4 pl-0 mt-4"
										)}
									{!tripData?.pod_file__flag ? (
										<Typography className="text-12"> {`${FILES_TYPES_NAMES["pod"]} `}</Typography>
									) : (
										<Link
											className="text-12 ml:text-13 break-normal py-4"
											key={"not_available_pod_file"}
											onClick={(e) => onclickFileLink(e, "", { pod_file: true })}
										>
											{`${FILES_TYPES_NAMES["pod"]} `}
										</Link>
									)}
								</div>
								<DocumentDetail
									icon={<Icon className="text-20 text-blue my-4">receipt_long</Icon>}
									title={FILES_TYPES_NAMES["pod"]}
									hasFile={!!tripData?.pod_file__flag}
									item={tripInvoice}
								/>
							</div>
						)}
						{tripData?.documents
							?.sort((a, b) => (PRIORITY_TYPE[a?.type] < PRIORITY_TYPE[b?.type] ? 1 : -1))
							?.map((file, i) => {
								const fileType = FILES_TYPES_NAMES[file.type];

								const totalFilesType = tripData?.documents.filter((f) => f.type === file.type);
								const index = totalFilesType.findIndex((f) => f.file_id?.[0] === file.file_id?.[0]);
								const fileTypeLabeling =
									totalFilesType.length === 1 ? fileType : fileType ? `${fileType} #${index + 1}` : "Custom File";
								return (
									<div
										key={file.file_id?.[0]}
										className="flex flex-row border-b-1 border-grey-300 items-center content-center w-full justify-between"
									>
										<div className="flex flex-row space-between w-full items-center content-center">
											{!readOnly && !nativeMobile && (
												<Radio
													className="pr-4 pl-0"
													checked={selectedLink?.id === file.file_id?.[0]}
													onChange={() =>
														onSetSelectedLink({
															id: file.file_id?.[0],
															link: fileService.buildLink(file?.file_id?.[0]),
														})
													}
													value={file.file_id?.[0]}
												/>
											)}

											{!file.file_id?.length ? (
												<Typography className="text-12">
													{file.type !== "other"
														? fileTypeLabeling
														: file.description
														? file.description
														: fileTypeLabeling}
												</Typography>
											) : (
												<Link
													className="text-12 ml:text-13 break-normal py-4"
													key={file.file_id?.[0]}
													onClick={(e) => onclickFileLink(e, fileService.buildLink(file.file_id?.[0]))}
												>
													{file.type !== "other"
														? fileTypeLabeling
														: file.description
														? file.description
														: fileTypeLabeling}
												</Link>
											)}
										</div>
										{createTooltip(
											<Icon className={`text-20 ${fileType ? "text-blue" : "text-pink"} my-4`}>
												{fileType ? "receipt_long" : "description"}
											</Icon>,
											fileTypeLabeling
										)}
									</div>
								);
							})}
					</DisplaySection>
				</div>
			</div>
			<div className="flex flex-col md:flex-row md:space-x-16">
				<DisplaySection classes={{ root: "md:w-full" }} title={"Notes"}>
					<Typography>{tripInvoice.notes || "No information"}</Typography>
				</DisplaySection>
			</div>
			<SmarthopConfirmDialog
				open={!!confirmationModel?.message}
				title={confirmationModel?.message}
				acceptMsg={confirmationModel?.acceptMsg}
				onClose={() => {
					typeof confirmationModel?.onCancel === "function" && confirmationModel?.onCancel();
					setConfirmationModel(null);
				}}
				onAccept={() => {
					if ((reason && confirmationModel?.showReasonForm) || !confirmationModel?.showReasonForm) {
						typeof confirmationModel?.onAccept === "function" && confirmationModel?.onAccept(reason);
						setConfirmationModel(null);
					} else {
						setReasonFormErrors([{ type: "reason", message: "Reason is required" }]);
					}
				}}
			>
				{confirmationModel?.showReasonForm && (
					<div className="pt-16">
						<SmarthopFormView
							content={{
								form: {
									noErrorMessage: true,
								},
								items: [
									{
										key: "reason",
										type: "text",
										label: "Reason",
										multiline: { rows: 5 },
									},
								],
							}}
							mode={"EDIT"}
							errors={reasonFormErrors}
							onChangeCommitted={handleReasonChange}
							trackChangedFields={["ALL"]}
						/>
					</div>
				)}
			</SmarthopConfirmDialog>
			{plaidToken && (
				<PlaidLink
					token={plaidToken}
					carrierId={carrierId}
					sourceId={sourceId}
					isSmartpayPayoutAccount={isSmartpayPayoutAccount}
					isReauthentication={smartpayAccountRequiresReauth}
				/>
			)}
		</>
	);
};

export default TripInvoicingDetails;
