import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Typography, FormControlLabel, Switch, Link, Checkbox, Icon } from "@material-ui/core";

// Components
import { SmarthopConfirmDialog } from "@smarthop/form";
import SmarthopDialogViewContainer from "@smarthop/views/SmarthopDialogViewContainer";
import SmarthopDialogViewLoadingStub from "@smarthop/views/SmarthopDialogViewLoadingStub";

// Services
import { getTrip, sendFiles } from "app/services/tripsServices";
import { getInvoiceForTrip, updateInvoiceStatus, sendTripInvoiceEmail } from "app/services/tripInvoicingServices";
import { getCarrierBranding, getCarrierInvoiceSettings } from "app/services/carrierServices";

// Utils and Tools
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { createTooltip } from "app/main/utils/tableUtils";
import { closeFormDialog, openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { DisplaySection } from "app/main/utils/uiUtils";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import EmailFieldWithChips from "app/main/profile/trips/EmailFieldWithChips";

// Const
const FILES_TYPES_NAMES = {
	invoice: "Invoice",
	pod: "Bill Of Lading",
	rate_confirmation: "Rate Confirmation",
	proof_of_delivery: "Proof Of Delivery",
	packing_list: "Packing List",
	lumper_receipt: "Lumper Receipt",
	"dock/warehouse_receipt": "Dock/Warehouse Receipt",
	scale_or_weight_receipt: "Scale or Weight Receipt",
	cargo_picture: "Cargo Picture",
	display_screen: "Display Screen",
	rateConf: "RC",
};

const PRIORITY_TYPE = {
	pod: 8,
	rate_confirmation: 7,
	proof_of_delivery: 6,
	packing_list: 5,
	lumper_receipt: 4,
	"dock/warehouse_receipt": 3,
	scale_or_weight_receipt: 2,
	cargo_picture: 1,
	display_screen: 0,
	other: -1,
};

const attachStyle = {
	POD: {
		color: "text-blue",
		icon: "receipt_long",
	},
	OTHER: {
		color: "text-pink",
		icon: "description",
	},
	RC: {
		color: "text-green",
		icon: "request_quote",
	},
	INVOICE: {
		color: "text-orange",
		icon: "receipt",
	},
};

function DialogFileSharing(props) {
	useEffect(() => {
		props.setTitle?.(props.dataIds?.from === "TRIP" ? "Send Trip Files" : "Send Invoice");
		props.setSize?.("max-w-lg");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const nativeMobile = useSelector(({ auth }) => auth.user.nativeMobile);

	const dataIds = props.dataIds;
	const carrierId = dataIds?.carrierId;
	const tripId = dataIds?.tripId;
	const invoiceId = dataIds?.invoiceId;
	const resend = dataIds?.resend;
	const from = dataIds?.from;

	const [files, setFiles] = useState([]);
	const [invoice, setInvoice] = useState([]);
	const [externalEmail, setExternalEmail] = useState();
	const [internalUsers, setInternalUsers] = useState([]);
	const [emailValue, setEmailValue] = useState();
	const [emailErrors, setEmailErrors] = useState([]);
	const [combinedDocuments, setCombinedDocuments] = useState(false);
	const [confirmationModel, setConfirmationModel] = useState();
	const [sending, setSending] = useState();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState();

	useEffect(() => {
		if (!tripId || !carrierId) return;

		(async () => {
			setLoading(true);

			try {
				const [trip, branding, invoice, internalCopy] = await Promise.all([
					getTrip(tripId, carrierId),
					getCarrierBranding(carrierId),
					getInvoiceForTrip(carrierId, tripId),
					getCarrierInvoiceSettings(carrierId),
				]);

				const files = [];
				const active = from === "INVOICE";

				const podFiles =
					trip?.pod_files?.sort((a, b) => (PRIORITY_TYPE[a?.type] < PRIORITY_TYPE[b?.type] ? 1 : -1)) ?? [];
				podFiles.forEach((item, i) => {
					const fileType = FILES_TYPES_NAMES[item.type];

					const totalFilesType = podFiles.filter((f) => f.type === item.type);
					const index = totalFilesType.findIndex((f) => f.file_id?.[0] === item.file_id?.[0]);
					const fileTypeLabeling =
						totalFilesType.length === 1
							? fileType
							: fileType
							? `${fileType} #${index + 1}`
							: `Custom File #${index + 1}`;

					const data = {
						file_id: item.file_id[0],
						type: item.type !== "other" ? "POD" : "OTHER",
						label: item.type !== "other" ? fileTypeLabeling : item.description,
						tooltip: fileTypeLabeling,
						active: active,
					};

					files.push(data);
				});

				if (invoice.invoice_file?.[0] && invoice.invoice_file__flag) {
					files.push({ file_id: invoice.invoice_file[0], type: "INVOICE", label: "Invoice", active: active });
					setInvoice(invoice);
				}
				if (trip?.rate_con_file?.[0] && trip?.rate_con_file__flag) {
					files.push({ file_id: trip?.rate_con_file[0], type: "RC", label: "Rate Confirmation", active: active });
				}
				setFiles(files);

				const emails = [];
				if (trip?.mail_broker) emails.push(trip?.mail_broker);
				if (trip?.broker_extra_contacts?.length)
					emails.push(...trip.broker_extra_contacts?.map((b) => b?.email).filter(Boolean));
				if (emails.length) setEmailValue(emails.toString());

				internalCopy?.internal_users_invoices_copy?.map((item) => (item.active = active));
				setInternalUsers(internalCopy?.internal_users_invoices_copy);

				setExternalEmail(branding.email_smtp?.authentication?.username ?? "invoices@smarthop.co");

				setLoading(false);
			} catch (e) {
				setError(e.errors?.[0]?.message ?? e.message);
				setLoading(false);
			}
		})();
		// eslint-disable-next-line
	}, [carrierId, tripId]);

	const hasCheckedFiles = useMemo(() => {
		const hasChecked = files.filter((file) => file.active);
		return hasChecked.length > 0;
		// eslint-disable-next-line
	}, [files]);

	const handlerAction = () => {
		const confirmation = {
			message: "Do you want to continue with the operation?",
			acceptMsg: "Yes, Send It",
			onCancel: () => {},
			onAccept: () => onSendFile(),
		};
		setConfirmationModel(confirmation);
	};

	const handlerInternalCopy = (event, field, internalUser) => {
		setInternalUsers(
			internalUsers.map((user) =>
				user.user_email === internalUser.user_email ? { ...user, [field]: event?.target?.checked } : user
			)
		);
	};

	const onSendFile = async () => {
		setSending(true);

		try {
			const brokerFiles = {};
			files.forEach((item) => {
				const field = item.type === "INVOICE" ? "invoice" : item.type === "RC" ? "rateConf" : item.file_id;
				brokerFiles[field] = item.active;
			});

			const data = {
				recipients: emailValue,
				internalFiles: internalUsers ?? {},
				brokerFiles,
				combinedDocuments,
			};

			let snackbarFlag = false;
			if (from === "TRIP") {
				data.invoiceId = invoice._id;
				await sendFiles(carrierId, tripId, data);

				snackbarFlag = true;
			} else if (from === "INVOICE") {
				if (resend) {
					data.resend = resend;
					await sendTripInvoiceEmail(carrierId, invoiceId, data);
				} else {
					await updateInvoiceStatus(carrierId, invoiceId, "SUBMITTED", data);
				}

				snackbarFlag = true;
			}

			if (snackbarFlag) showSnackbar(snackbar, "Emails sent successfully!", "success");
			dispatch(incrementDataRevision({ event: "tripsRevision" }));
			dispatch(closeFormDialog());
			setSending(false);
		} catch (e) {
			// If update failed, show error message
			showSnackbar(snackbar, e.errors?.[0]?.message, "error", { duration: 7000 });
			setSending(false);
		}
	};

	if (loading || error) {
		return <SmarthopDialogViewLoadingStub nativeMobile={nativeMobile} loading={loading} error={error} />;
	}

	return (
		<SmarthopDialogViewContainer
			nativeMobile={nativeMobile}
			tabComponents={[
				{
					key: "VIEW",
					title: "View",
					component: (
						<div className={"w-full mr-0 md:mr-12" + (sending ? " opacity-60 pointer-events-none " : "")}>
							<DisplaySection classes={{ root: "md:w-full" }} title={<div className="mb-8">Broker Copy</div>}>
								<div className="flex w-full flex-col md:flex-col">
									<div className="flex w-full flex-row">
										<Typography className="whitespace-nowrap pr-5 mt-2 font-semibold">{"From:"}</Typography>{" "}
										<Typography className="whitespace-nowrap pr-5 ml-3 mt-1 text-14">{externalEmail}</Typography>
									</div>
									<Typography className="text-10 mb-9 text-grey-600">
										To change SMTP configuration go to Settings, then Details menu and SMTP Mail Configuration section.
									</Typography>{" "}
									<Typography className="whitespace-nowrap pr-4 mt-2 font-semibold">{"To:"}</Typography>{" "}
									<EmailFieldWithChips
										emailArr={emailValue?.split(",")?.map((e) => e.trim())}
										emailErrors={emailErrors}
										setEmailErrors={setEmailErrors}
										setEmailValue={setEmailValue}
									/>
								</div>
							</DisplaySection>

							<DisplaySection
								classes={{ root: "md:w-full -mt-12 -mb-2" }}
								title={
									<div className="w-full flex flex-row content-center justify-between items-center">
										<div>Attachments</div>
										<FormControlLabel
											control={
												<Switch
													checked={combinedDocuments}
													onChange={(event) => setCombinedDocuments(event.target.checked)}
												/>
											}
											label="Combined"
										/>
									</div>
								}
							>
								<div className="border border-grey-300 bg-grey-50 rounded px-16 py-2">
									{files?.map((file, i) => {
										return (
											<div
												key={file.file_id}
												className={
													"flex flex-row border-grey-200 items-center justify-between py-5 " +
													(i + 1 !== files.length ? "border-b-1" : "")
												}
											>
												<div className="flex flex-row space-between w-full items-center content-center">
													<Checkbox
														value={file.file_id}
														checked={file.active}
														onChange={(event) => {
															setFiles(
																files.map((item) =>
																	item.file_id === file.file_id ? { ...item, active: event?.target?.checked } : item
																)
															);
														}}
														size="small"
													/>
													<Typography className="text-12 ml:text-13 break-normal py-4">{file.label}</Typography>
												</div>
												{createTooltip(
													<Icon className={`text-20 ${attachStyle[file.type]?.color} mt-5`}>
														{attachStyle[file.type]?.icon}
													</Icon>,
													file.tooltip ?? file.label
												)}
											</div>
										);
									})}
								</div>
							</DisplaySection>

							<DisplaySection classes={{ root: "md:w-full" }} title={<div className="mt-24 mb-8">Internal Copy</div>}>
								{internalUsers?.length
									? internalUsers?.map((internalUser, i) => (
											<div key={i} className="flex flex-col md:flex-row mt-4">
												<div className="flex w-full flex-col md:flex-row">
													<div className="flex flex-row md:w-5/12 items-center content-center pr-4">
														<Checkbox
															size="small"
															checked={internalUser?.active}
															onChange={(event) => handlerInternalCopy(event, "active", internalUser)}
															className="pr-4 pl-0"
														/>
														<Typography className="whitespace-nowrap pr-4 mt-2 font-semibold">{"To:"}</Typography>{" "}
														<Typography className="whitespace-nowrap pr-5 mt-2 ">{internalUser.user_email}</Typography>
													</div>
													<div className="flex w-full flex-col md:flex-row md:w-7/12 border border-grey-300 bg-grey-50 rounded pl-4">
														<div
															key={internalUser.user_email + "invoice"}
															className="flex flex-row items-center content-center w-full ml-8"
														>
															<Checkbox
																size="small"
																checked={internalUser?.invoice}
																onChange={(event) => handlerInternalCopy(event, "invoice", internalUser)}
																className="pr-4 pl-0"
															/>
															<Typography className="text-12 ml:text-13 break-normal py-4">
																{`${FILES_TYPES_NAMES["invoice"]} `}
															</Typography>
														</div>
														<div
															key={internalUser.user_email + "pod"}
															className="flex flex-row items-center content-center w-full ml-8"
														>
															<Checkbox
																size="small"
																checked={internalUser?.pod}
																onChange={(event) => handlerInternalCopy(event, "pod", internalUser)}
																className="pr-4 pl-0"
															/>
															<Typography className="text-12 ml:text-13 break-normal py-4">
																{`${FILES_TYPES_NAMES["pod"]} `}
															</Typography>
														</div>
														<div
															key={internalUser.user_email + "rateConf"}
															className="flex flex-row items-center content-center w-full ml-8"
														>
															<Checkbox
																size="small"
																checked={internalUser?.rate_conf}
																onChange={(event) => handlerInternalCopy(event, "rate_conf", internalUser)}
																className="pr-4 pl-0"
															/>
															<Typography className="text-12 ml:text-13 break-normal py-4">
																{`${FILES_TYPES_NAMES["rateConf"]} `}
															</Typography>
														</div>
														<div
															key={internalUser.user_email + "others"}
															className="flex flex-row items-center content-center w-full ml-8"
														>
															<Checkbox
																size="small"
																checked={internalUser?.[i]?.others}
																onChange={(event) => handlerInternalCopy(event, "others", internalUser)}
																className="pr-4 pl-0"
															/>
															<Typography className="text-12 ml:text-13 break-normal py-4">Others</Typography>
														</div>
													</div>
												</div>
											</div>
									  ))
									: !nativeMobile && (
											<Typography className="mb-9 text-grey-600">
												{`To set recepients for internal copy go to the `}
												<Link
													onClick={() => {
														dispatch(
															openLoadedFormDialog({
																viewId: "CARRIER_INFO_VIEW",
																dataIds: { carrierId: carrierId },
															})
														);
													}}
												>
													Details Page
												</Link>
											</Typography>
									  )}
							</DisplaySection>

							<SmarthopConfirmDialog
								open={!!confirmationModel?.message}
								title={confirmationModel?.message}
								acceptMsg={confirmationModel?.acceptMsg}
								onClose={() => {
									typeof confirmationModel?.onCancel === "function" && confirmationModel?.onCancel();
									setConfirmationModel(null);
								}}
								onAccept={() => {
									confirmationModel?.onAccept();
									setConfirmationModel(null);
								}}
							/>
						</div>
					),
				},
			]}
			footerActions={[
				{
					key: "send",
					title: sending ? "Sending..." : "Send",
					disabled: emailErrors?.length || !hasCheckedFiles || !emailValue || sending,
					hideOnDesktop: false,
					hideOnMobile: false,
					onClick: () => handlerAction(),
					style: { align: "RIGHT", notCollapsible: true, icon: "send", primary: true },
				},
			]}
		/>
	);
}

export default DialogFileSharing;
