import { formatDate } from "app/main/utils/dateUtils";
import { fetchCredentialsStatus } from "app/store/search/credentialsSlice";

const getCredentialId = (ownerUser, isGeneric, expireAt) => {
	const login = isGeneric ? "Trial Credential" : ownerUser ? ownerUser : "-";
	const expiration = expireAt ? `(Expires ${formatDate(expireAt)})` : "";
	return `${login} ${expiration}`;
};

const DAT_FIELDS = [
	{ type: "section", label: "Version 3" },
	{ key: "email", type: "text", label: "Email", required: true, field: { noAutocomplete: true } },

	{ type: "section", label: "Version 2" },
	{
		type: "group",
		content: {
			items: [
				{ key: "username", type: "text", label: "Username", required: true, field: { noAutocomplete: true } },
				{
					key: "password",
					type: "password",
					icon: "visibility",
					label: "Password",
					required: true,
					field: { noAutocomplete: true },
				},
			],
		},
	},
	{ type: "action", action: "SUBMIT" },
];
const NEWTRUL_FIELDS = [
	{ key: "email", type: "email", label: "Email", required: true },
	{ type: "action", action: "SUBMIT" },
];
const ECHO_FIELDS = [
	{
		type: "group",
		content: {
			items: [
				{ key: "client_id", type: "text", label: "Client ID", required: true },
				{ key: "client_secret", type: "password", icon: "visibility", label: "Client Secret", required: true },
			],
		},
	},
	{ type: "action", action: "SUBMIT" },
];
const COYOTE_FIELDS = [
	{
		type: "group",
		content: {
			items: [
				{ key: "client_id", type: "text", label: "Client ID", required: true },
				{ key: "client_secret", type: "password", icon: "visibility", label: "Client Secret", required: true },
			],
		},
	},
	{ key: "rc_receiver_email", type: "text", label: "RC Receiver Email", required: true },
	{ type: "action", action: "SUBMIT" },
];
const EMERGE_FIELDS = [
	{
		type: "group",
		content: {
			items: [
				{ key: "enable_integration", type: "checkbox", label: "I want to integrate with Emerge", required: true },
				{
					key: "carrier_mc_number",
					type: "text",
					label: "MC #",
					translate: "MC_NUMBER",
					required: false,
					disabled: true,
				},
			],
		},
	},
	{ type: "action", action: "SUBMIT" },
];
const LOADSMART_FIELDS = [
	{
		type: "group",
		content: {
			items: [
				{
					key: "owner_name",
					type: "text",
					label: "Owner's Name",
					translate: "OWNER_NAME",
					required: true,
				},
				{
					key: "rc_receiver_email",
					type: "text",
					label: "RC Receiver Email",
					translate: "USER_EMAIL",
					required: true,
				},
			],
		},
	},
	{
		type: "group",
		content: {
			items: [
				{
					key: "carrier_mc_number",
					type: "text",
					label: "MC #",
					translate: "MC_NUMBER",
					required: false,
					disabled: true,
				},
				{
					key: "carrier_dot_number",
					type: "text",
					label: "DOT #",
					translate: "USER_EMAIL",
					required: false,
					disabled: true,
				},
			],
		},
	},
	{ type: "action", action: "SUBMIT" },
];
const CHROBINSON_FIELDS = [
	{ key: "tcode", type: "text", label: "TCode", required: true },
	{ key: "rc_receiver_email", type: "text", label: "RC Receiver Email", required: true },
	{ type: "action", action: "SUBMIT" },
];
const UBER_FLEETS_FIELDS = [
	{
		key: "app",
		type: "autocomplete",
		label: "Select the app",
		translate: "",
		required: true,
		autocomplete: {
			provider: "smarthop",
			url: "api/profile/carriers/integrations/brokers/uber/apps",
			preloadDefault: true,
		},
	},
	{ type: "action", action: "SUBMIT" },
];
const UBER_FIELDS = [];

const GLOBAL_FORM = (brokerId, title) => {
	return {
		urlGET: "api/profile/carriers/:carrierId/integrations/brokers/:brokerId",
		urlPOST: "api/profile/carriers/:carrierId/integrations/brokers/:brokerId",
		urlPUT: "api/profile/carriers/:carrierId/integrations/brokers/:brokerId",
		urlDELETE: "api/profile/carriers/:carrierId/integrations/brokers/:brokerId",
		idKey: "_id",
		triggerEvent: "profileRevision",
		listenEvent: "profileRevision",
		header: {
			create: { label: "Set " + title },
			edit: { label: "Edit " + title },
			delete: { label: "Clear " + title },
		},
		content: {
			delete: {
				items: [
					{
						type: "message",
						builder: (item) => `Would you like to permanently delete current ${title}?`,
					},
					{
						type: "group",
						content: {
							items: [
								{ type: "action", action: "CANCEL" },
								{ type: "action", action: "DELETE" },
							],
						},
					},
				],
			},
			view: {
				items: [
					{
						key: "statusExternal",
						type: "text",
						label: "Status",
						builder: (data) => {
							const label =
								data?.statusExternal === "ACCEPTED"
									? "Connected"
									: data?.statusExternal === "REQUESTED"
									? "Pending"
									: data?.statusExternal === "DISABLED"
									? "Disabled"
									: "No Connected";
							return label;
						},
						required: true,
					},
					{
						key: "rejectReason",
						type: "text",
						label: "Reject Reason",
						hidden: (item) => !item?.rejectReason || item?.statusExternal !== "REJECTED",
					},
					{
						key: "ownerUser",
						type: "text",
						label: "Credential ID",
						required: true,
						builder: (data) => {
							if (brokerId === "dat") {
								if (data?.status === "NOT INTEGRATED") {
									return "-";
								}

								if (data?.isGenericRest && data?.isGenericSoap) {
									return getCredentialId(data?.ownerUserRest, data?.isGenericRest, data?.expireAtRest);
								} else {
									const REST = getCredentialId(data?.ownerUserRest, data?.isGenericRest, data?.expireAtRest);
									const SOAP = getCredentialId(data?.ownerUserSoap, data?.isGenericSoap, data?.expireAtSoap);
									return `${REST} / ${SOAP}`;
								}
							} else {
								return getCredentialId(data?.ownerUser, data?.isGeneric, data?.expireAt);
							}
						},
					},
				],
			},
			create: {
				items:
					brokerId === "dat"
						? DAT_FIELDS
						: brokerId === "newtrul"
						? NEWTRUL_FIELDS
						: brokerId === "echo"
						? ECHO_FIELDS
						: brokerId === "emerge"
						? EMERGE_FIELDS
						: brokerId === "coyote"
						? COYOTE_FIELDS
						: brokerId === "loadsmart"
						? LOADSMART_FIELDS
						: brokerId === "chrobinson"
						? CHROBINSON_FIELDS
						: brokerId === "uber-fleet"
						? UBER_FLEETS_FIELDS
						: brokerId === "uber"
						? UBER_FIELDS
						: [],
			},
			edit: {
				noInitValidation: true,
				sameAs: "create",
				onFinished: (result, dispatch) => {
					dispatch(fetchCredentialsStatus({ carrierId: result?.id, noReload: true }));
				},
			},
		},
	};
};

export { GLOBAL_FORM };
